import React from 'react';
import { SiYoutubemusic } from 'react-icons/si';
import { Button } from '@chakra-ui/react';

export const LinkYouTubeMusic: React.FC = () => (
  <Button
    as="a"
    href="https://music.youtube.com/channel/UCIrdPNtFKoIJp9izjyL110g"
    target="_blank"
    colorScheme="red"
    leftIcon={<SiYoutubemusic />}
  >
    YouTubeMusic
  </Button>
);
