import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from '../image/Image';

export const HeaderTitle: React.FC = () => (
  <Link to="/">
    <Image
      src={`${process.env.PUBLIC_URL}/logo.png`}
      alt="MYUKKE. OFFICIAL SITE"
      width="300px"
    />
  </Link>
);
