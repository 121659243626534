import { AspectRatio } from '@chakra-ui/react';
import React from 'react';

// プレイリスト「OFFICIAL MYUKKE. SONGS」の最新動画を表示
// https://www.youtube.com/playlist?list=PL31axQ8v4u7_cbFGM3K5Yrfl9liUa3IG_
export const LatestRelease: React.FC = () => (
  // eslint-disable-next-line jsx-a11y/iframe-has-title
  <AspectRatio maxW="100%" ratio={16 / 9}>
    <iframe
      src="https://www.youtube.com/embed/videoseries?list=PL31axQ8v4u7_cbFGM3K5Yrfl9liUa3IG_"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </AspectRatio>
);
