import React from 'react';
import { Timeline } from 'react-twitter-widgets';

export const Twitter: React.FC = () => (
  <Timeline
    dataSource={{
      sourceType: 'profile',
      screenName: 'youtalkq69310',
    }}
    options={{
      height: '500',
    }}
  />
);
