import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import React from 'react';
import ReactPaginate from 'react-paginate';

type PagenationProps = {
  dataCounts: number;
  perPage: number;
  pageNumber: number;
  onChange: (value: { selected: number }) => void;
};

export const Pagination: React.FC<PagenationProps> = ({
  dataCounts,
  perPage,
  pageNumber,
  onChange,
}) => (
  <ReactPaginate
    previousLabel={<ChevronLeftIcon fontSize="xl" />}
    nextLabel={<ChevronRightIcon fontSize="xl" />}
    breakLabel="..."
    forcePage={pageNumber}
    pageCount={Math.ceil(dataCounts / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
    marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
    pageRangeDisplayed={2} // アクティブなページを基準にして、そこからいくつページ数を表示するか
    onPageChange={onChange} // クリック時のfunction
    containerClassName="pagination justify-center" // ul(pagination本体)
    pageClassName="page-item" // li
    pageLinkClassName="page-link rounded-full" // a
    activeClassName="active" // active.li
    activeLinkClassName="active" // active.li < a
    // 戻る・進む関連
    previousClassName="page-item" // li
    nextClassName="page-item" // li
    previousLinkClassName="previous-link"
    nextLinkClassName="next-link"
    // 先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくする
    disabledClassName="disabled-button d-none"
    // 中間ページの省略表記関連
    breakClassName="page-item"
    breakLinkClassName="page-link"
  />
);
