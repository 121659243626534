import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../container/Container';

export const Profile: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <Container direction="column" fontSize={{ base: 'md', md: 'lg' }} gap="4">
      {/* 日本語 */}
      {i18n.language === 'ja' && (
        <>
          <Text>
            2012年から楽曲制作を開始。
            <br />
            2016年のACゲームへの楽曲提供を機に様々な場面での楽曲提供を開始。
          </Text>
          <Text>
            これまで楽曲公募を通して、様々な商業用音楽ゲームへ楽曲提供を行う。
            その他フリーゲームや各種イベントに向けた楽曲も制作。
          </Text>
          <Text>
            一度聴いたら忘れられない明るさ、グルーヴ感のある楽曲や、民族系の独特な雰囲気の楽曲を得意とする。
          </Text>
          <Text>
            本人の手と目によって生まれたキャラクター&quot;Nironta&quot;や、
            <br />
            オレンジ色の女の子のキャラクター&quot;Fulia&quot;などを生み出した。
          </Text>
          <Text>
            代表曲：OCCHOCO-REST-LESS、The 89&apos;s Momentum、The 90&apos;s
            Decision、FULi AUTO SHOOTER、NO ONE YES MAN 等。
          </Text>
        </>
      )}
      {/* 英語 */}
      {i18n.language === 'en' && (
        <>
          <Text>
            They have started writing song since 2012. <br />
            And providing song for AC game in 2016, which led to providing song for
            various situations.
          </Text>
          <Text>
            Until now, they have provided many songs to various commercial rhythm games
            through public song submission.
            <br />
            They also produces song for various free rhythm games or various events.
          </Text>
          <Text>
            They specializes in songs with a bright, groovy feel that you can&apos;t
            forget once you hear it, and a unique ethnic atmosphere songs.
          </Text>
          <Text>
            They have created the character &quot;Nironta&quot; with their own hands and
            eyes, and the character &quot;Fulia&quot;, an orange girl.
          </Text>
          <Text>
            Signature song: OCCHOCO-REST-LESS, The 89&apos;s Momentum, The 90&apos;s
            Decision, FULi AUTO SHOOTER, NO ONE YES MAN etc.
          </Text>
        </>
      )}
    </Container>
  );
};
