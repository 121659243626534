import React, { FC, ReactNode, useMemo } from 'react';
import { SectionTitle } from '../atoms/texts/SectionTitle';
import { Box } from '../container/Box';
import { Container } from '../container/Container';

type CardProps = {
  children: ReactNode;
  title: string;
};

export const Card: FC<CardProps> = ({ children, title }) =>
  useMemo(
    () => (
      <Box
        bg="white"
        w={{ base: '100%', md: '48%' }}
        maxW={{ base: '100%', md: 'calc(50% - 16px)' }}
        borderRadius="20"
        p={4}
      >
        <Container direction="column" gap="4">
          <SectionTitle fontSize={{ base: 'xl', md: '2xl' }} text={title} />
          <hr style={{ borderTop: '2px solid #036' }} />
          {children}
        </Container>
      </Box>
    ),
    [children, title]
  );
