import { Work } from '../types/Work';

export const JAWorks: Work[] = [
  {
    id: '2024-05-02',
    date: '2024-05',
    client: 'Cheetah Technology Corporation Limited',
    category: 'スマホゲーム',
    title: 'Rolling Sky',
    url: 'https://www.taptap.io/app/33603857',
    description: '楽曲 "Macropolis" が収録',
    media: undefined,
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2024-05-01',
    date: '2024-05',
    client: 'Dream Engine Games',
    category: 'スマホ音楽ゲーム',
    title: 'Rotaeno',
    url: 'https://www.rotaeno.com/en',
    description: '書き下ろし楽曲 "Triad of Dryad" が収録',
    media: [{ type: 'YouTube', id: 'qhbPL88SwtM' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2024-04-01',
    date: '2024-04',
    client: 'Tanchiky.com',
    category: 'M3-2024 春',
    title: 'ULTRA SYNERGY MATRIX アルティメットコレクション',
    url: 'https://usm.tanchiky.com/',
    description: '楽曲 "ULTRA SYNERGY MATRIX (MYUKKE. REMIX)" を提供',
    media: [{ type: 'YouTube', id: 'GVvrwJpuENI' }],
    tags: ['other', 'cd'],
  },
  {
    id: '2024-04-01',
    date: '2024-04',
    client: 'BTworks',
    category: 'スマホ音楽ゲーム',
    title: 'Orzmic',
    url: 'https://twitter.com/orzmic',
    description: '書き下ろし楽曲 "OiL-CAD-OiL" が収録',
    media: [{ type: 'YouTube', id: 'xTDtFnv6AS4' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2024-02-15',
    date: '2024-02',
    client: '虹视游戏工作室',
    category: 'PCゲーム',
    title: 'Doloc Town',
    url: 'https://store.steampowered.com/app/2285550/Doloc_Town/',
    description:
      'トレイラー映像BGMとして "Duallel Spirit" を提供',
    media: [{ type: 'YouTube', id: '3_SyeQ_UlX0' }],
    tags: ['commercial', 'game'],
  },
  {
    id: '2024-02-08',
    date: '2024-02',
    client: 'KONAMI',
    category: 'AC音楽ゲーム',
    title: 'SOUND VOLTEX',
    url: 'https://p.eagate.573.jp/game/sdvx/',
    description:
      '楽曲 "CHOVERY GOOSE!!!" が収録\n（BEMANI PRO LEAGUE -SEASON 3- オリジナル楽曲コンテスト"入賞"楽曲）',
    media: undefined,
    tags: ['commercial', 'game'],
  },
  {
    id: '2024-01-17',
    date: '2024-01',
    client: 'SEGA',
    category: 'キャンペーンCD',
    title: 'maimai でらっくす グッズキャンペーンDiSC -BUDDiES-',
    url: 'info-maimai.sega.jp/4895/',
    description: '書き下ろし楽曲 "Comet Panto Men! (Long ver.)" が収録',
    media: undefined,
    tags: ['commercial', 'exclusive', 'cd'],
  },
  {
    id: '2023-12-2',
    date: '2023-12',
    client: 'C4Cat',
    category: 'スマホ音楽ゲーム',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description:
      '楽曲 "Hiyaiya!" が収録\n（Paradigm: Rebootとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-12-03',
    date: '2023-12',
    client: 'Andamiro',
    category: 'AC音楽ゲーム',
    title: 'Pump It Up',
    url: 'https://www.piugame.com/',
    description: '楽曲 "Yo! Say!! Fairy!!!" が収録',
    media: [{ type: 'YouTube', id: '7Z_xm13ySpE' }],
    tags: ['commercial', 'game'],
  },
  {
    id: '2023-12-02',
    date: '2023-12',
    client: 'KONAMI',
    category: 'AC音楽ゲーム',
    title: 'SOUND VOLTEX',
    url: 'https://p.eagate.573.jp/game/sdvx/',
    description:
      '楽曲 "TOKAKU=ALMiRAJ" が収録\n（KACオリジナル楽曲コンテスト2023"入賞"楽曲）',
    media: undefined,
    tags: ['commercial', 'game'],
  },
  {
    id: '2023-12-01',
    date: '2023-12',
    client: 'Dream Engine Games',
    category: 'スマホ音楽ゲーム',
    title: 'Rotaeno',
    url: 'https://www.rotaeno.com/en',
    description:
      '楽曲 "NO ONE YES MAN" が収録\n（Phigrosとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-11-01',
    date: '2023-11',
    client: 'ppy pty Ltd.',
    category: 'osu! World Cup 2023',
    title: 'osu!',
    url: 'https://osu.ppy.sh/wiki/en/Tournaments/OWC/2023',
    description: '書き下ろし楽曲 "Mach Roger" を提供',
    media: [{ type: 'YouTube', id: 'ZMQkyaN_F4k' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-10-05',
    date: '2023-10',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '書き下ろし楽曲 "RakiRaki Rebuilders!!!" が収録',
    media: [{ type: 'YouTube', id: 'IiKbuvFafUA' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-10-04',
    date: '2023-10',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '楽曲 "OTTAMA GAZER" が収録',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-10-03',
    date: '2023-10',
    client: 'Shoya Otake',
    category: 'スマホ音楽ゲーム',
    title: 'Liminality',
    url: 'https://liminality.ninja/',
    description: '楽曲 "The 89\'s Momentum" が収録',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-10-02',
    date: '2023-10',
    client: 'Restrike.',
    category: 'M3-2023 秋',
    title: 'Rebeat.vol2',
    url: 'https://rebeat2.netlify.app/',
    description: '楽曲 "SUND ME STOP" を提供',
    media: [{ type: 'YouTube', id: '28GO8jqi2PI' }],
    tags: ['other', 'cd'],
  },
  {
    id: '2023-10-01',
    date: '2023-10',
    client: 'FRENZ2023',
    category: '映像上映イベント',
    title: 'FRENZ2023',
    url: 'https://frenz.jp/2023/',
    description: '映像作品 "Yestalgia for Futurity" の楽曲を制作',
    media: [{ type: 'YouTube', id: 'lJ1X_CKnr14' }],
    tags: ['other'],
  },
  {
    id: '2023-09-03',
    date: '2023-09',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '書き下ろし楽曲 "JACK PT BOSS" が収録',
    media: [{ type: 'YouTube', id: '26qN8oJpIg8' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-09-02',
    date: '2023-09',
    client: 'sky way studio',
    category: 'スマホ音楽ゲーム',
    title: 'Berry Melody',
    url: 'https://mobile.twitter.com/BerryMelody4',
    description: '書き下ろし楽曲 "ASTRO+NYAUT" を提供',
    media: [{ type: 'YouTube', id: '0M1114fV7Io' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-09-01',
    date: '2023-09',
    client: 'C4Cat',
    category: 'スマホ音楽ゲーム',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description:
      '楽曲 "SUTTA MONDAY" が収録\n（Paradigm: Rebootとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-08-02',
    date: '2023-08',
    client: 'Corsace Open 2023',
    category: 'PC音楽ゲーム',
    title: 'osu!',
    url: 'https://corsace.io/',
    description: '書き下ろし楽曲 "Unknown Amnasia" を提供',
    media: [{ type: 'YouTube', id: '1IrH00r_JGk' }],
    tags: ['non-commercial', 'game'],
  },
  {
    id: '2023-08-01',
    date: '2023-08',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description:
      '書き下ろし楽曲 "ABABABA BURST" が収録\n（Neon Abyssとのコラボレーション）',
    media: [{ type: 'YouTube', id: 'lJCvkPcvccA' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-07-03',
    date: '2023-07',
    client: 'Resurrection Cup 2023',
    category: 'PC音楽ゲーム',
    title: 'osu!',
    url: 'https://www.rescup.xyz/',
    description: '書き下ろし楽曲 "Friendly Gigant Fire" を提供',
    media: [{ type: 'YouTube', id: 'bH4W2MN0IzU' }],
    tags: ['non-commercial', 'game'],
  },
  {
    id: '2023-07-02',
    date: '2023-07',
    client: 'KONAMI',
    category: 'AC音楽ゲーム',
    title: 'SOUND VOLTEX',
    url: 'https://p.eagate.573.jp/game/sdvx/',
    description: '楽曲 "Shera" が収録（MÚSECAより）',
    media: undefined,
    tags: ['commercial', 'game'],
  },
  {
    id: '2023-07-01',
    date: '2023-07',
    client: 'Para Games',
    category: 'スマホ音楽ゲーム',
    title: 'RYCEAM',
    url: 'https://space.bilibili.com/1675655640',
    description: '書き下ろし楽曲 "ENDRUiD" を提供',
    media: [{ type: 'YouTube', id: 'wSfSAhYsj60' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-06-02',
    date: '2023-06',
    client: 'I-Inferno',
    category: 'PC音楽ゲーム',
    title: 'MUSYNX',
    url: 'https://store.steampowered.com/app/952040/MUSYNX/',
    description:
      '楽曲 "BiBiC HEART" が収録\n（Orzmicとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-06-01',
    date: '2023-06',
    client: 'ppy pty Ltd.',
    category: 'osu!catch World Cup 2023',
    title: 'osu!',
    url: 'https://osu.ppy.sh/wiki/en/Tournaments/CWC/2023',
    description: '書き下ろし楽曲 "Anti Safety Zone" を提供',
    media: [{ type: 'YouTube', id: 'jsLXEc1SHqg' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-04-04',
    date: '2023-04',
    client: 'ああ…翡翠茶漬け… + Defiant Groovings',
    category: 'M3-2023 春',
    title: 'Trrricksters!!',
    url: 'https://trrricksters.tumblr.com/',
    description: '楽曲 "Glooorious!! (MYUKKE. REMIX)" を提供',
    media: [{ type: 'YouTube', id: 'yExvgLqQmeI' }],
    tags: ['other', 'cd'],
  },
  {
    id: '2023-04-03',
    date: '2023-04',
    client: '晨雾幻想·器乐团',
    category: 'PC音楽ゲーム',
    title: '东方求闻音录',
    url: 'https://store.steampowered.com/app/1744750/_/',
    description: '書き下ろし東方アレンジ楽曲 "NEW OLD SOV" が収録',
    media: [{ type: 'YouTube', id: 'kVZ8a3Sq8LU' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-04-02',
    date: '2023-04',
    client: 'SEGA',
    category: 'AC音楽ゲーム',
    title: 'maimai でらっくす',
    url: 'https://maimai.sega.jp/',
    description:
      '楽曲 "The 90\'s Decision" が収録\n（Muse Dashとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-04-01',
    date: '2023-04',
    client: 'Pigeon Games',
    category: 'スマホ音楽ゲーム',
    title: 'Rizline',
    url: 'https://twitter.com/Rizline_PGS',
    description: '楽曲 "NO ONE YES MAN" が収録',
    media: undefined,
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-03-01',
    date: '2023-03',
    client: 'SEGA',
    category: 'AC音楽ゲーム',
    title: 'maimai でらっくす',
    url: 'https://maimai.sega.jp/',
    description: '書き下ろし楽曲 "Comet Panto Men!" が収録',
    media: [{ type: 'YouTube', id: 'NJ8q75LDiuY' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-02-01',
    date: '2023-02',
    client: 'I-Inferno',
    category: 'モバイル・PC音楽ゲーム',
    title: 'MUSYNC / MUSYNX',
    url: 'https://store.steampowered.com/app/952040/MUSYNX/',
    description:
      '楽曲 "HATEN CODE 583" が収録\n（Berry Melodyとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-01-05',
    date: '2023-01',
    client: 'TunerGames',
    category: 'スマホ音楽ゲーム',
    title: 'Paradigm: Reboot',
    url: 'https://twitter.com/ParadigmReboot',
    description: '書き下ろし楽曲 "Equality of Equites" が収録',
    media: [{ type: 'YouTube', id: 'aMfkxOIbyt0' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-01-04',
    date: '2023-01',
    client: 'TunerGames',
    category: 'スマホ音楽ゲーム',
    title: 'Paradigm: Reboot',
    url: 'https://twitter.com/ParadigmReboot',
    description: '書き下ろし楽曲 "SUTTA MONDAY" が収録',
    media: [{ type: 'YouTube', id: 'uPElYBQvKaI' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-01-03',
    date: '2023-01',
    client: 'TunerGames',
    category: 'スマホ音楽ゲーム',
    title: 'Paradigm: Reboot',
    url: 'https://twitter.com/ParadigmReboot',
    description: '書き下ろし楽曲 "Hiyaiya!" が収録',
    media: [{ type: 'YouTube', id: 'vM9ojm7jvxw' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-01-02',
    date: '2023-01',
    client: 'TunerGames',
    category: 'スマホ音楽ゲーム',
    title: 'Paradigm: Reboot',
    url: 'https://twitter.com/ParadigmReboot',
    description: '楽曲 "OCCHOCO-REST-LESS" が収録',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-01-01',
    date: '2023-01',
    client: 'Lavoie Studios',
    category: 'PC音楽ゲーム',
    title: 'Rhythm Bang',
    url: 'https://store.steampowered.com/app/2156810/Rhythm_Bang/',
    description: '書き下ろし楽曲 "Clout da Exp!!!" が収録',
    media: [{ type: 'YouTube', id: '2KmgBZSSEVU' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2022-12-01',
    date: '2022-12',
    client: 'KONAMI',
    category: 'PC音楽ゲーム',
    title: 'beatmania IIDX INFINITAS',
    url: 'https://p.eagate.573.jp/game/infinitas/2/index.html',
    description: '楽曲 "DO-IT-AMA-SITE!!!" が収録',
    tags: ['commercial', 'game'],
  },
  {
    id: '2022-11-02',
    date: '2022-11',
    client: 'Noxy Games',
    category: 'スマホ音楽ゲーム',
    title: 'Lanota',
    url: 'https://www.facebook.com/lanota/',
    description: '楽曲 "OCCHOCO-REST-LESS" が収録',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-11-01',
    date: '2022-11',
    client: 'PeroPeroGames',
    category: '楽曲コンテスト',
    title: 'COSMIC RADIO PEROLIST',
    url: 'https://perolist.peropero.net/',
    description: 'スペシャル審査員として参加',
    tags: ['contest', 'other'],
  },
  {
    id: '2022-09-01',
    date: '2022-09',
    client: 'FRENZ2022',
    category: '映像上映イベント',
    title: 'FRENZ2022',
    url: 'https://frenz.jp/2022/',
    description: '1日目昼の部OP楽曲 "alamo de parture" を制作',
    media: [{ type: 'YouTube', id: 'Jkumfffts_c' }],
    tags: ['other'],
  },
  {
    id: '2022-07-02',
    date: '2022-07',
    client: 'DeltaGames',
    category: 'スマホ音楽ゲーム',
    title: 'Schiphalast',
    url: 'https://space.bilibili.com/261899921',
    description: '書き下ろし楽曲 "Delta Entropy" を提供',
    media: [{ type: 'YouTube', id: 'MK78mrFQNDw' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2022-07-01',
    date: '2022-07',
    client: 'Dream Engine Games',
    category: 'スマホ音楽ゲーム',
    title: 'Rotaeno',
    url: 'https://www.rotaeno.com/en',
    description: '楽曲 "Light Into Scheelite" が収録',
    media: [{ type: 'YouTube', id: 'nEYRjlJaAfo' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-06-03',
    date: '2022-06',
    client: 'WizDream Games',
    category: 'スマホ音楽ゲーム',
    title: 'SparkLine',
    url: 'https://wizdreamgames.com/',
    description: '書き下ろし楽曲 "Horizon Ramblue" を提供',
    media: [{ type: 'YouTube', id: 'KfFvR0lz3wc' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2022-06-02',
    date: '2022-06',
    client: 'Anarch Entertainment',
    category: 'AC音楽ゲーム',
    title: 'NOISZ ARC⌖CODA',
    url: 'https://exa.ac/ja/games/noisz-arc-coda-2/?v=0f177369a3b7',
    description: '楽曲 "OTTAMA GAZER" と "YOKKORASHOW-TIME" が収録',
    media: [{ type: 'YouTube', id: 'sCHmrj0kOWw' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-06-01',
    date: '2022-06',
    client: 'Mg Studio',
    category: 'スマホ音楽ゲーム',
    title: 'Gliece',
    url: 'https://play.google.com/store/apps/details?id=com.mg.gliece',
    description: '楽曲 "YUMEND" が収録',
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-05-01',
    date: '2022-05',
    client: 'TAITO',
    category: 'AC音楽ゲーム',
    title: 'グルーヴコースター',
    url: 'https://groovecoaster.jp/',
    description:
      '楽曲 "#AKKAN_BABY" が収録\n（10周年記念オリジナル楽曲コンテスト入賞楽曲）',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2022-04-03',
    date: '2022-04',
    client: 'sky way studio',
    category: 'スマホ音楽ゲーム',
    title: 'Berry Melody',
    url: 'https://mobile.twitter.com/BerryMelody4',
    description: '書き下ろし楽曲 "HATEN CODE 583" を提供',
    media: [{ type: 'YouTube', id: 'aO8t96veOsw' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2022-04-02',
    date: '2022-04',
    client: 'Pigeon Games',
    category: 'スマホ音楽ゲーム',
    title: 'Phigros',
    url: 'https://twitter.com/phigros_pgs',
    description: '楽曲 "FULi AUTO BUSTER" が収録',
    media: [{ type: 'YouTube', id: 'kffFExCiMC0' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-04-01',
    date: '2022-04',
    client: 'NEOWIZ',
    category: 'PC音楽ゲーム',
    title: 'DJMAX RESPECT V',
    url: 'https://store.steampowered.com/app/960170/DJMAX_RESPECT_V/',
    description:
      '楽曲 "The 90\'s Decision" が収録\n（Muse Dashとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2022-02-02',
    date: '2022-02',
    client: 'MYUKKE_DOT',
    category: 'EP',
    title: 'WE LOVE MYUKKE. EP Vol.2',
    url: 'https://myukke.bandcamp.com/album/we-love-myukke-ep-vol-2',
    description: '"WE LOVE MYUKKE. EP Vol.2" をリリース',
    media: [{ type: 'YouTube', id: 'n9hieYBze_4' }],
    tags: ['other'],
  },
  {
    id: '2022-02-01',
    date: '2022-02',
    client: 'Anarch Entertainment',
    category: 'スマホ音楽ゲーム',
    title: 'NOISZ STΔRLIVHT',
    url: 'https://anarch.games/starlivht/',
    description: '楽曲 "FULi AUTO SHOOTER" が収録',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-01-01',
    date: '2022-01',
    client: '10min Studio',
    category: 'スマホ音楽ゲーム',
    title: '溯 Resux',
    url: 'https://space.bilibili.com/229690533',
    description: '書き下ろし楽曲 "scars of emptiness" を提供',
    media: [{ type: 'YouTube', id: 'RXuAysM9g7k' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-12-02',
    date: '2021-12',
    client: 'Quesera Games',
    category: 'スマホ音楽ゲーム',
    title: 'KALPA',
    url: 'https://queseragames.com/',
    description:
      '楽曲 "NO ONE YES MAN" が収録\n（Phigrosとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-12-01',
    date: '2021-12',
    client: 'TAITO',
    category: '音楽ゲーム',
    title: 'グルーヴコースター ワイワイパーティー!!!!',
    url: 'https://groovecoaster.com/switch/',
    description:
      '楽曲 "#AKKAN_BABY" が収録\n（10周年記念オリジナル楽曲コンテスト入賞楽曲）',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2021-11-01',
    date: '2021-11',
    client: 'Marvelous',
    category: 'AC音楽ゲーム',
    title: 'WACCA',
    url: 'https://wacca.marv.jp/',
    description:
      '楽曲 "The 90\'s Decision" が収録\n（Muse Dashとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-10-01',
    date: '2021-10',
    client: 'C4Cat',
    category: 'スマホ音楽ゲーム',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description: 'Dynamix submission 2019にて採用された\n楽曲 "Kalis Mind" が収録',
    media: [{ type: 'YouTube', id: '2pmmDzNI9KY' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2021-09-04',
    date: '2021-09',
    client: 'BTworks',
    category: 'スマホ音楽ゲーム',
    title: 'Orzmic',
    url: 'https://twitter.com/orzmic',
    description: '書き下ろし楽曲 "Era Breath" が収録',
    media: [{ type: 'YouTube', id: 'XMDy8LZ-Fls' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-09-03',
    date: '2021-09',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '書き下ろし楽曲 "ARIGA THESIS" が収録',
    media: [{ type: 'YouTube', id: 'VMcV2DhVpGg' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-09-02',
    date: '2021-09',
    client: 'Synthnova',
    category: 'スマホ音楽ゲーム',
    title: 'RAVON',
    url: 'https://synthnova-ravon.weebly.com/',
    description:
      '楽曲 "OCCHOCO-REST-LESS" が収録\n（Dynamixとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-09-01',
    date: '2021-09',
    client: '7th Beat Games',
    category: 'PC音楽ゲーム',
    title: 'Rhythm Doctor',
    url: 'https://store.steampowered.com/app/774181/Rhythm_Doctor/',
    description:
      '楽曲 "The 90\'s Decision" が収録\n（Muse Dashとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-08-02',
    date: '2021-08',
    client: 'Para Games',
    category: 'スマホ音楽ゲーム',
    title: 'RYCEAM',
    url: 'https://space.bilibili.com/1675655640',
    description: '書き下ろし楽曲 "The Revival of Eighteen" を提供',
    media: [{ type: 'YouTube', id: 'moukFP9aFVk' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-08-01',
    date: '2021-08',
    client: 'Glacia Studio',
    category: 'スマホ音楽ゲーム',
    title: 'AnXiens',
    url: 'https://space.bilibili.com/2107362911',
    description: '書き下ろし楽曲 "ViViTAL MONO" を提供',
    media: [{ type: 'YouTube', id: 'WaNTcXj_OOM' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-07-01',
    date: '2021-07',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '楽曲 "OCCHOCO-REST-LESS" が収録',
    media: undefined,
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2021-05-01',
    date: '2021-05',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description:
      '楽曲 "NO ONE YES MAN" が収録\n（Phigrosとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-02-01',
    date: '2021-02',
    client: 'BTworks',
    category: 'スマホ音楽ゲーム',
    title: 'Orzmic',
    url: 'https://twitter.com/orzmic',
    description: '書き下ろし楽曲 "BiBiC HEART" と、\n既存楽曲 "OTTAMA GAZER" が収録',
    media: [{ type: 'YouTube', id: 'H3V5iEuqrdM' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-01-01',
    date: '2021-01',
    client: 'Rayark',
    category: 'スマホ音楽ゲーム',
    title: 'Cytus2',
    url: 'https://www.rayark.com/g/cytus2/',
    description:
      '楽曲 "The 89\'s Momentum", "The 90\'s Decision" が収録\n（Muse Dashとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2020-12-01',
    date: '2020-12',
    client: 'ppy pty Ltd.',
    category: 'PC音楽ゲーム',
    title: 'osu!',
    url: 'https://osu.ppy.sh/beatmaps/artists/121',
    description: 'Featured Artistとして複数楽曲のライセンスを付与',
    media: undefined,
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2020-11-01',
    date: '2020-11',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '書き下ろし楽曲 "The 90\'s Decision" が収録',
    media: [{ type: 'YouTube', id: 'TNeatjG_nUw' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2020-10-01',
    date: '2020-10',
    client: '非可逆リズム',
    category: 'M3-2020 秋',
    title: undefined,
    url: undefined,
    description: '楽曲 "モリモリあつし Remixed by MYUKKE. - 紅 (MYUKKE. REMIX)" を提供',
    media: [{ type: 'YouTube', id: '0sLhAUX_dJ8' }],
    tags: ['other'],
  },
  {
    id: '2020-08-05',
    date: '2020-08',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '楽曲 "Ginevra" が収録',
    media: [{ type: 'YouTube', id: 'CLkuCQz44Pw' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2020-08-04',
    date: '2020-08',
    client: 'Andamiro',
    category: 'スマホ音楽ゲーム',
    title: 'Pump It Up M',
    url: 'https://apps.apple.com/jp/app/pump-it-up-m-beat-finger-step/id1489909857',
    description: '楽曲 "Yo! Say!! Fairy!!!" が収録',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2020-08-03',
    date: '2020-08',
    client: 'Rayark',
    category: 'スマホ音楽ゲーム',
    title: 'Cytus2',
    url: 'https://www.rayark.com/g/cytus2/',
    description: '楽曲 "Quinsialyn" が収録',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2020-08-02',
    date: '2020-08',
    client: 'I-Inferno',
    category: 'スマホ音楽ゲーム',
    title: 'MUSYNX',
    url: 'https://store.steampowered.com/app/952040/MUSYNX/',
    description:
      '楽曲 "NO ONE YES MAN" が収録\n（Phigrosとのコラボレーションによる移植収録）',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2020-08-01',
    date: '2020-08',
    client: 'Pigeon Games',
    category: 'スマホ音楽ゲーム',
    title: 'Phigros',
    url: 'https://twitter.com/phigros_pgs',
    description: '書き下ろし楽曲 "NO ONE YES MAN" が収録',
    media: [{ type: 'YouTube', id: 'ULORJbgWuNQ' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2020-07-02',
    date: '2020-07',
    client: 'KONAMI',
    category: 'AC音楽ゲーム',
    title: 'SOUND VOLTEX',
    url: 'https://p.eagate.573.jp/game/sdvx/',
    description: '楽曲 "DO-IT-AMA-SITE!!!" が収録',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2020-07-01',
    date: '2020-07',
    client: 'MYUKKE_DOT',
    category: 'EP',
    title: 'WE LOVE MYUKKE. EP',
    url: 'https://myukke.bandcamp.com/album/we-love-myukke-ep',
    description: '"WE LOVE MYUKKE. EP" をリリース',
    media: [{ type: 'YouTube', id: '0UhntYbh9mY' }],
    tags: ['other'],
  },
  {
    id: '2020-04-01',
    date: '2020-04',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '楽曲 "FULi AUTO SHOOTER" が収録',
    media: [{ type: 'YouTube', id: 'K0R-FZrSLPo' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2020-03-02',
    date: '2020-03',
    client: 'NEOWIZ',
    category: 'PC音楽ゲーム',
    title: 'DJMAX RESPECT V',
    url: 'https://store.steampowered.com/app/960170/DJMAX_RESPECT_V/',
    description: '楽曲 "WONDER $LOT 777" が収録',
    media: [{ type: 'YouTube', id: 'ARRdg_Ujbio' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2020-03-01',
    date: '2020-03',
    client: 'NEOWIZ',
    category: 'スマホ音楽ゲーム',
    title: 'TAPSONIC TOP',
    url: 'https://tapsonic-top.jp/',
    description: '楽曲 "GAI∀PEX" が収録',
    media: [{ type: 'YouTube', id: 'FiGz1IseiPA' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2020-02-02',
    date: '2020-02',
    client: 'Nanjing AQUATRAX',
    category: '楽曲コンテスト',
    title: undefined,
    url: undefined,
    description: 'INVAXION公募FirstBattle にて楽曲採用',
    media: undefined,
    tags: ['contest', 'game'],
  },
  {
    id: '2020-02-01',
    date: '2020-02',
    client: 'Andamiro',
    category: '楽曲コンテスト',
    title: undefined,
    url: undefined,
    description:
      'The 3rd PUMP IT UP CONTENTS CREATION CONTEST\nにて "Yo! Say!! Fairy!!!" が入賞',
    media: [{ type: 'YouTube', id: 'clHwJ31kU_Q' }],
    tags: ['contest', 'game'],
  },
  {
    id: '2019-11-01',
    date: '2019-11',
    client: 'Andamiro',
    category: 'スマホ音楽ゲーム',
    title: 'Pump It Up M',
    url: 'https://apps.apple.com/jp/app/pump-it-up-m-beat-finger-step/id1489909857',
    description: '楽曲 "Bestest Frenemy!" が収録',
    media: [{ type: 'YouTube', id: 'Y6jUGwPUSMU' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2019-10-01',
    date: '2019-10',
    client: 'PeroPeroGames',
    category: '音楽ゲーム',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '楽曲 "The 89\'s Momentum" が収録',
    media: [{ type: 'YouTube', id: 'OualnKdbJGo' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2019-09-01',
    date: '2019-09',
    client: 'C4Cat',
    category: 'スマホ音楽ゲーム',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description: 'Dynamix submission 2017にて採用された\n楽曲 "ROAD to 773H" が収録',
    media: [{ type: 'YouTube', id: 'NuoPvHd5OGs' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2019-08-03',
    date: '2019-08',
    client: 'lowiro',
    category: 'スマホ音楽ゲーム',
    title: 'Arcaea',
    url: 'https://arcaea.lowiro.com/en',
    description: 'Arcaea楽曲コンテスト2019にて採用された\n楽曲 "AI[UE]OON" が収録',
    media: [{ type: 'SoundCloud', id: '675480218' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2019-08-02',
    date: '2019-08',
    client: 'Pigeon Games',
    category: 'スマホ音楽ゲーム',
    title: 'Phigros',
    url: 'https://twitter.com/phigros_pgs',
    description: '楽曲 "FULi AUTO SHOOTER" が収録',
    media: [{ type: 'YouTube', id: 'K0R-FZrSLPo' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2019-08-01',
    date: '2019-08',
    client: 'AECRNIA',
    category: 'PC音楽ゲーム',
    title: 'URO2',
    url: 'https://keishoabe.wixsite.com/uro2',
    description: '楽曲公募にて採用された楽曲 "Chocomaca Runaway!" が収録',
    media: [{ type: 'SoundCloud', id: '663404258' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2019-07-01',
    date: '2019-07',
    client: 'Bit192 Labs',
    category: '楽曲コンテスト',
    title: undefined,
    url: 'http://tonesphere.com/sc2019/index_j.html',
    description:
      'Tone Sphere/Bit192 楽曲公募にて\n楽曲 "The 89\'s Momentum" が佳作を受賞',
    media: [{ type: 'YouTube', id: 'OualnKdbJGo' }],
    tags: ['contest', 'game'],
  },
  {
    id: '2019-03-02',
    date: '2019-03',
    client: 'BOOTH',
    category: 'グッズ販売',
    title: undefined,
    url: 'https://myukke.booth.pm/items/1281208',
    description: 'Nirontaアクリルキーホルダーの販売開始',
    media: undefined,
    tags: ['other'],
  },
  {
    id: '2019-03-01',
    date: '2019-03',
    client: 'NEOWIZ',
    category: 'PC音楽ゲーム',
    title: 'TAPSONIC BOLD',
    url: 'https://store.steampowered.com/app/938220/TAPSONIC_BOLD/',
    description: '楽曲 "GAI∀PEX" が収録',
    media: [{ type: 'YouTube', id: 'FiGz1IseiPA' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2019-01-01',
    date: '2019-01',
    client: 'Anarch Entertainment',
    category: 'PC音楽ゲーム',
    title: 'NOISZ',
    url: 'https://store.steampowered.com/app/681530/NOISZ/',
    description: '楽曲 "YOKKORASHOW-TIME" が収録',
    media: [{ type: 'SoundCloud', id: '437097003' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2018-08-01',
    date: '2018-08',
    client: 'Andamiro',
    category: '楽曲コンテスト',
    title: undefined,
    url: undefined,
    description:
      'The 2nd PUMP IT UP CONTENTS CREATION CONTEST\nにて"Bestest Frenemy!" が本選進出',
    media: undefined,
    tags: ['contest', 'game'],
  },
  {
    id: '2018-07-02',
    date: '2018-07',
    client: 'Diverse System',
    category: 'コミックマーケット94',
    title: 'CELT CHALLENGE 2',
    url: 'https://diverse.jp/dvsp-0203/',
    description: '楽曲公募にて採用された楽曲 "Quinsialyn" が収録',
    media: [{ type: 'SoundCloud', id: '482527122' }],
    tags: ['contest', 'cd'],
  },
  {
    id: '2018-07-01',
    date: '2018-07',
    client: 'AECRNIA',
    category: 'PC音楽ゲーム',
    title: 'URO',
    url: 'https://keishoabe.wixsite.com/urogames',
    description: '楽曲公募にて採用された楽曲 "Fickle Rule" が収録',
    media: [{ type: 'SoundCloud', id: '663403742' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2018-04-01',
    date: '2018-04',
    client: 'NEOWIZ',
    category: '楽曲コンテスト',
    title: undefined,
    url: undefined,
    description:
      'YOU X NEOWIZ Music Festivalにて\n楽曲 "WONDER $LOT 777" と "GAI∀PEX" が受賞',
    media: undefined,
    tags: ['contest', 'game'],
  },
  {
    id: '2018-01-01',
    date: '2018-01',
    client: '龍獣楽団',
    category: 'スマホ音楽ゲーム',
    title: 'ちくたくコンチェルト シンフォニア',
    url: 'http://ryujuorchestra.com/products/tikutakuconcert/',
    description: 'ちくたくコンチェルト第6回楽曲公募にて\n楽曲 "TEYAN DAY" が採用',
    media: [{ type: 'SoundCloud', id: '403986864' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2017-10-02',
    date: '2017-10',
    client: '波動Lucario',
    category: 'スマホ音楽ゲーム',
    title: 'DanceRail',
    url: 'https://apps.apple.com/jp/app/dancerail3/id1442619889',
    description: '楽曲 "Elusive Impetus" が収録',
    media: [{ type: 'SoundCloud', id: '322265075' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2017-10-01',
    date: '2017-10',
    client: 'C4Cat',
    category: 'スマホ音楽ゲーム',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description: 'Dynamix submission 2017にて採用された\n楽曲 "OCCHOCO-REST-LESS" が収録',
    media: [{ type: 'YouTube', id: '9A7KhgBY0cQ' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2017-09-01',
    date: '2017-09',
    client: 'Andamiro',
    category: '楽曲コンテスト',
    title: undefined,
    url: undefined,
    description: 'PUMP IT UP CONTENTS CREATION CONTEST\nにて"Bestest Fam!" が本選進出',
    media: undefined,
    tags: ['contest', 'game'],
  },
  {
    id: '2017-05-01',
    date: '2017-05',
    client: 'KONAMI',
    category: 'AC音楽ゲーム',
    title: 'MUSECA 1+1/2',
    url: 'https://p.eagate.573.jp/game/museca/msc_1half/top/index.html',
    description: 'BEMANI NEWFACE CONTESTにて\n採用された楽曲 "Shera" が収録',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
];
