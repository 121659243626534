import { Work } from '../types/Work';

export const ENWorks: Work[] = [
  {
    id: '2024-05-02',
    date: '2024-05',
    client: 'Cheetah Technology Corporation Limited',
    category: 'Mobile Game',
    title: 'Rolling Sky',
    url: 'https://www.taptap.io/app/33603857',
    description: '"Macropolis" was included.',
    media: undefined,
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2024-05-01',
    date: '2024-05',
    client: 'Dream Engine Games',
    category: 'Mobile Rhythm Game',
    title: 'Rotaeno',
    url: 'https://www.rotaeno.com/en',
    description: 'New written song "Triad of Dryad" was included.',
    media: [{ type: 'YouTube', id: 'qhbPL88SwtM' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2024-04-01',
    date: '2024-04',
    client: 'Tanchiky.com',
    category: 'M3-2024 Spring',
    title: 'ULTRA SYNERGY MATRIX Ultimate Collection',
    url: 'https://usm.tanchiky.com/',
    description: 'Offered "ULTRA SYNERGY MATRIX (MYUKKE. REMIX)".',
    media: [{ type: 'YouTube', id: 'GVvrwJpuENI' }],
    tags: ['other', 'cd'],
  },
  {
    id: '2024-04-01',
    date: '2024-04',
    client: 'BTworks',
    category: 'Mobile Rhythm Game',
    title: 'Orzmic',
    url: 'https://twitter.com/orzmic',
    description: 'New written song "OiL-CAD-OiL" was included.',
    media: [{ type: 'YouTube', id: 'xTDtFnv6AS4' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2024-02-15',
    date: '2024-02',
    client: '虹视游戏工作室',
    category: 'PC Game',
    title: 'Doloc Town',
    url: 'https://store.steampowered.com/app/2285550/Doloc_Town/',
    description:
      'Provided "Duallel Spirit" as BGM for the trailer video.',
    media: [{ type: 'YouTube', id: '3_SyeQ_UlX0' }],
    tags: ['commercial', 'game'],
  },
  {
    id: '2024-02-08',
    date: '2024-02',
    client: 'KONAMI',
    category: 'Arcade Rhythm Game',
    title: 'SOUND VOLTEX',
    url: 'https://p.eagate.573.jp/game/sdvx/',
    description:
      '"CHOVERY GOOSE!!!" was included.\n(BEMANI PRO LEAGUE -SEASON 3- original song contest "winning" song)',
    media: undefined,
    tags: ['commercial', 'game'],
  },
  {
    id: '2024-01-17',
    date: '2024-01',
    client: 'SEGA',
    category: 'Campaign CD',
    title: 'maimai DX Goods Campaign DiSC -BUDDiES-',
    url: 'info-maimai.sega.jp/4895/',
    description: 'Provided the newly written song "Comet Panto Men! (Long ver.)"',
    media: undefined,
    tags: ['commercial', 'exclusive', 'cd'],
  },
  {
    id: '2023-12-22',
    date: '2023-12',
    client: 'C4Cat',
    category: 'Mobile Rhythm Game',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description: '"Hiyaiya!" was included.\n(collaboration with Paradigm: Reboot)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-12-03',
    date: '2023-12',
    client: 'Andamiro',
    category: 'Arcade Rhythm Game',
    title: 'Pump It Up',
    url: 'https://www.piugame.com/',
    description: '"Yo! Say!! Fairy!!!" was included.',
    media: [{ type: 'YouTube', id: '7Z_xm13ySpE' }],
    tags: ['commercial', 'game'],
  },
  {
    id: '2023-12-02',
    date: '2023-12',
    client: 'KONAMI',
    category: 'Arcade Rhythm Game',
    title: 'SOUND VOLTEX',
    url: 'https://p.eagate.573.jp/game/sdvx/',
    description:
      '"TOKAKU=ALMiRAJ" was included.\n(KAC original song contest 2023 "winning" song)',
    media: undefined,
    tags: ['commercial', 'game'],
  },
  {
    id: '2023-12-01',
    date: '2023-12',
    client: 'Dream Engine Games',
    category: 'Mobile Rhythm Game',
    title: 'Rotaeno',
    url: 'https://www.rotaeno.com/en',
    description: '"NO ONE YES MAN" was included.\n(collaboration with Phigros)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-11-01',
    date: '2023-11',
    client: 'ppy pty Ltd.',
    category: 'osu! World Cup 2023',
    title: 'osu!',
    url: 'https://osu.ppy.sh/wiki/en/Tournaments/OWC/2023',
    description: 'Provided the newly written song "Mach Roger"',
    media: [{ type: 'YouTube', id: 'ZMQkyaN_F4k' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-10-05',
    date: '2023-10',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: 'Provided the newly written song "RakiRaki Rebuilders!!!"',
    media: [{ type: 'YouTube', id: 'IiKbuvFafUA' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-10-04',
    date: '2023-10',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '"OTTAMA GAZER" was included.',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-10-03',
    date: '2023-10',
    client: 'Shoya Otake',
    category: 'Mobile Rhythm Game',
    title: 'Liminality',
    url: 'https://liminality.ninja/',
    description: '"The 89\'s Momentum" was included.',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-10-02',
    date: '2023-10',
    client: 'Restrike.',
    category: 'M3-2023 Autumn',
    title: 'Rebeat.vol2',
    url: 'https://rebeat2.netlify.app/',
    description: 'Offered "SUND ME STOP".',
    media: [{ type: 'YouTube', id: '28GO8jqi2PI' }],
    tags: ['other', 'cd'],
  },
  {
    id: '2023-10-01',
    date: '2023-10',
    client: 'FRENZ2023',
    category: 'Video Screening Event',
    title: 'FRENZ2023',
    url: 'https://frenz.jp/2023/',
    description: 'Produced the music for the video work "Yestalgia for Futurity".',
    media: [{ type: 'YouTube', id: 'lJ1X_CKnr14' }],
    tags: ['other'],
  },
  {
    id: '2023-09-03',
    date: '2023-09',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: 'Provided the newly written song "JACK PT BOSS".',
    media: [{ type: 'YouTube', id: '26qN8oJpIg8' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-09-02',
    date: '2023-09',
    client: 'sky way studio',
    category: 'Mobile Rhythm Game',
    title: 'Berry Melody',
    url: 'https://mobile.twitter.com/BerryMelody4',
    description: 'Provided the newly written song "ASTRO+NYAUT".',
    media: [{ type: 'YouTube', id: '0M1114fV7Io' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-09-01',
    date: '2023-09',
    client: 'C4Cat',
    category: 'Mobile Rhythm Game',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description: '"SUTTA MONDAY" was included.\n(collaboration with Paradigm: Reboot)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-08-02',
    date: '2023-08',
    client: 'Corsace Open 2023',
    category: 'PC Rhythm Game',
    title: 'osu!',
    url: 'https://corsace.io/',
    description: 'Provided the newly written song "Unknown Amnasia".',
    media: [{ type: 'YouTube', id: '1IrH00r_JGk' }],
    tags: ['non-commercial', 'game'],
  },
  {
    id: '2023-08-01',
    date: '2023-08',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description:
      'Provided the newly written song "ABABABA BURST".\n(collaboration with Neon Abyss)',
    media: [{ type: 'YouTube', id: 'lJCvkPcvccA' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-07-03',
    date: '2023-07',
    client: 'Resurrection Cup 2023',
    category: 'PC Rhythm Game',
    title: 'osu!',
    url: 'https://www.rescup.xyz/',
    description: 'Provided the newly written song "Friendly Gigant Fire".',
    media: [{ type: 'YouTube', id: 'bH4W2MN0IzU' }],
    tags: ['non-commercial', 'game'],
  },
  {
    id: '2023-07-02',
    date: '2023-07',
    client: 'KONAMI',
    category: 'Arcade Rhythm Game',
    title: 'SOUND VOLTEX',
    url: 'https://p.eagate.573.jp/game/sdvx/',
    description: '"Shera" was included. (from MÚSECA)',
    media: undefined,
    tags: ['commercial', 'game'],
  },
  {
    id: '2023-07-01',
    date: '2023-07',
    client: 'Para Games',
    category: 'Mobile Rhythm Game',
    title: 'RYCEAM',
    url: 'https://space.bilibili.com/1675655640',
    description: 'Provided the newly written song "ENDRUiD".',
    media: [{ type: 'YouTube', id: 'wSfSAhYsj60' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-06-02',
    date: '2023-06',
    client: 'I-Inferno',
    category: 'PC Rhythm Game',
    title: 'MUSYNX',
    url: 'https://store.steampowered.com/app/952040/MUSYNX/',
    description: '"BiBiC HEART" was included.\n(collaboration with Orzmic)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-06-01',
    date: '2023-06',
    client: 'ppy pty Ltd.',
    category: 'osu!catch World Cup 2023',
    title: 'osu!',
    url: 'https://osu.ppy.sh/wiki/en/Tournaments/CWC/2023',
    description: 'Offered the newly written song "Anti Safety Zone".',
    media: [{ type: 'YouTube', id: 'jsLXEc1SHqg' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-04-04',
    date: '2023-04',
    client: 'ああ…翡翠茶漬け… + Defiant Groovings',
    category: 'M3-2023 spring',
    title: 'Trrricksters!!',
    url: 'https://trrricksters.tumblr.com/',
    description: 'Offered "Glooorious!! (MYUKKE. REMIX)".',
    media: [{ type: 'YouTube', id: 'yExvgLqQmeI' }],
    tags: ['other', 'cd'],
  },
  {
    id: '2023-04-03',
    date: '2023-04',
    client: 'Team Mist Fantasy',
    category: 'PC Rhythm Game',
    title: 'TouHou Music Recording',
    url: 'https://store.steampowered.com/app/1744750/_/',
    description: 'Provided the newly written TouHou arranged song "NEW OLD SOV".',
    media: [{ type: 'YouTube', id: 'kVZ8a3Sq8LU' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-04-02',
    date: '2023-04',
    client: 'SEGA',
    category: 'Arcade Rhythm Game',
    title: 'maimai DX',
    url: 'https://maimai.sega.jp/',
    description: '"The 90\'s Decision" was included.\n(collaboration with Muse Dash)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-04-01',
    date: '2023-04',
    client: 'Pigeon Games',
    category: 'Mobile Rhythm Game',
    title: 'Rizline',
    url: 'https://twitter.com/Rizline_PGS',
    description: '"NO ONE YES MAN" was included.',
    media: undefined,
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-03-01',
    date: '2023-03',
    client: 'SEGA',
    category: 'Arcade Rhythm Game',
    title: 'maimai DX',
    url: 'https://maimai.sega.jp/',
    description: 'Provided the newly written song "Comet Panto Men!".',
    media: [{ type: 'YouTube', id: 'NJ8q75LDiuY' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-02-01',
    date: '2023-02',
    client: 'I-Inferno',
    category: 'Mobile / PC Rhythm Game',
    title: 'MUSYNC / MUSYNX',
    url: 'https://store.steampowered.com/app/952040/MUSYNX/',
    description: '"HATEN CODE 583" was included.\n(collaboration with Berry Melody)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2023-01-05',
    date: '2023-01',
    client: 'TunerGames',
    category: 'Mobile Rhythm Game',
    title: 'Paradigm: Reboot',
    url: 'https://twitter.com/ParadigmReboot',
    description: 'Provided the newly written song "Equality of Equites".',
    media: [{ type: 'YouTube', id: 'aMfkxOIbyt0' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-01-04',
    date: '2023-01',
    client: 'TunerGames',
    category: 'Mobile Rhythm Game',
    title: 'Paradigm: Reboot',
    url: 'https://twitter.com/ParadigmReboot',
    description: 'Provided the newly written song "SUTTA MONDAY".',
    media: [{ type: 'YouTube', id: 'uPElYBQvKaI' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-01-03',
    date: '2023-01',
    client: 'TunerGames',
    category: 'Mobile Rhythm Game',
    title: 'Paradigm: Reboot',
    url: 'https://twitter.com/ParadigmReboot',
    description: 'Provided the newly written song "Hiyaiya!".',
    media: [{ type: 'YouTube', id: 'vM9ojm7jvxw' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2023-01-02',
    date: '2023-01',
    client: 'TunerGames',
    category: 'Mobile Rhythm Game',
    title: 'Paradigm: Reboot',
    url: 'https://twitter.com/ParadigmReboot',
    description: '"OCCHOCO-REST-LESS" was included.',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2023-01-01',
    date: '2023-01',
    client: 'Lavoie Studios',
    category: 'PC Rhythm Game',
    title: 'Rhythm Bang',
    url: 'https://store.steampowered.com/app/2156810/Rhythm_Bang/',
    description: 'Provided the newly written song "Clout da Exp!!!".',
    media: [{ type: 'YouTube', id: '2KmgBZSSEVU' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2022-12-01',
    date: '2022-12',
    client: 'KONAMI',
    category: 'PC Rhythm Game',
    title: 'beatmania IIDX INFINITAS',
    url: 'https://p.eagate.573.jp/game/infinitas/2/index.html',
    description: '"DO-IT-AMA-SITE!!!" was included.',
    tags: ['commercial', 'game'],
  },
  {
    id: '2022-11-02',
    date: '2022-11',
    client: 'Noxy Games',
    category: 'Mobile Rhythm Game',
    title: 'Lanota',
    url: 'https://www.facebook.com/lanota/',
    description: '"OCCHOCO-REST-LESS" was included.',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-11-01',
    date: '2022-11',
    client: 'PeroPeroGames',
    category: 'Song Submission',
    title: 'COSMIC RADIO PEROLIST',
    url: 'https://perolist.peropero.net/',
    description: 'Participate as a special judge',
    tags: ['contest', 'other'],
  },
  {
    id: '2022-09-01',
    date: '2022-09',
    client: 'FRENZ2022',
    category: 'Video Screening Event',
    title: 'FRENZ2022',
    url: 'https://frenz.jp/2022/',
    description:
      'Produced the OP song "alamo de parture"\nfor the daytime part of the first day.',
    media: [{ type: 'YouTube', id: 'Jkumfffts_c' }],
    tags: ['other'],
  },
  {
    id: '2022-07-02',
    date: '2022-07',
    client: 'DeltaGames',
    category: 'Mobile Rhythm Game',
    title: 'Schiphalast',
    url: 'https://space.bilibili.com/261899921',
    description: 'Provided the newly written song "Delta Entropy".',
    media: [{ type: 'YouTube', id: 'MK78mrFQNDw' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2022-07-01',
    date: '2022-07',
    client: 'Dream Engine Games',
    category: 'Mobile Rhythm Game',
    title: 'Rotaeno',
    url: 'https://www.rotaeno.com/en',
    description: '"Light Into Scheelite" was included.',
    media: [{ type: 'YouTube', id: 'nEYRjlJaAfo' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-06-03',
    date: '2022-06',
    client: 'WizDream Games',
    category: 'Mobile Rhythm Game',
    title: 'SparkLine',
    url: 'https://wizdreamgames.com/',
    description: 'Provided the newly written song "Horizon Ramblue".',
    media: [{ type: 'YouTube', id: 'KfFvR0lz3wc' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2022-06-02',
    date: '2022-06',
    client: 'Anarch Entertainment',
    category: 'Arcade Rhythm Game',
    title: 'NOISZ ARC⌖CODA',
    url: 'https://exa.ac/ja/games/noisz-arc-coda-2/?v=0f177369a3b7',
    description: '"OTTAMA GAZER" & "YOKKORASHOW-TIME" was included.',
    media: [{ type: 'YouTube', id: 'sCHmrj0kOWw' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-06-01',
    date: '2022-06',
    client: 'Mg Studio',
    category: 'Mobile Rhythm Game',
    title: 'Gliece',
    url: 'https://play.google.com/store/apps/details?id=com.mg.gliece',
    description: '"YUMEND" was included.',
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-05-01',
    date: '2022-05',
    client: 'TAITO',
    category: 'Arcade Rhythm Game',
    title: 'GROOVE COASTER',
    url: 'https://groovecoaster.jp/',
    description:
      '"#AKKAN_BABY" was included.\n(winning song of the 10th anniversary original song contest)',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2022-04-03',
    date: '2022-04',
    client: 'sky way studio',
    category: 'Mobile Rhythm Game',
    title: 'Berry Melody',
    url: 'https://space.bilibili.com/207342884',
    description: 'Provided the newly written song "HATEN CODE 583".',
    media: [{ type: 'YouTube', id: 'aO8t96veOsw' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2022-04-02',
    date: '2022-04',
    client: 'Pigeon Games',
    category: 'Mobile Rhythm Game',
    title: 'Phigros',
    url: 'https://twitter.com/phigros_pgs',
    description: '"FULi AUTO BUSTER" was included.',
    media: [{ type: 'YouTube', id: 'kffFExCiMC0' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-04-01',
    date: '2022-04',
    client: 'NEOWIZ',
    category: 'PC Rhythm Game',
    title: 'DJMAX RESPECT V',
    url: 'https://store.steampowered.com/app/960170/DJMAX_RESPECT_V/',
    description: '"The 90\'s Decision" was included.\n(collaboration with Muse Dash)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2022-02-02',
    date: '2022-02',
    client: 'MYUKKE_DOT',
    category: 'EP',
    title: 'WE LOVE MYUKKE. EP Vol.2',
    url: 'https://myukke.bandcamp.com/album/we-love-myukke-ep-vol-2',
    description: '"WE LOVE MYUKKE. EP Vol.2" was released.',
    media: [{ type: 'YouTube', id: 'n9hieYBze_4' }],
    tags: ['other'],
  },
  {
    id: '2022-02-01',
    date: '2022-02',
    client: 'Anarch Entertainment',
    category: 'Mobile Rhythm Game',
    title: 'NOISZ STΔRLIVHT',
    url: 'https://anarch.games/starlivht/',
    description: '"FULi AUTO SHOOTER" was included.',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2022-01-01',
    date: '2022-01',
    client: '10min Studio',
    category: 'Mobile Rhythm Game',
    title: '溯 Resux',
    url: 'https://space.bilibili.com/229690533',
    description: 'Provided the newly written song "scars of emptiness".',
    media: [{ type: 'YouTube', id: 'RXuAysM9g7k' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-12-02',
    date: '2021-12',
    client: 'Quesera Games',
    category: 'Mobile Rhythm Game',
    title: 'KALPA',
    url: 'https://queseragames.com/',
    description: '"NO ONE YES MAN" was included.\n(collaboration with Phigros)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-12-01',
    date: '2021-12',
    client: 'TAITO',
    category: 'Rhythm Game',
    title: 'Groove Coaster: Wai Wai Party!!!!',
    url: 'https://groovecoaster.com/switch/',
    description:
      '"#AKKAN_BABY" was included.\n(winning song of the 10th anniversary original song contest)',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2021-11-01',
    date: '2021-11',
    client: 'Marvelous',
    category: 'Arcade Rhythm Game',
    title: 'WACCA',
    url: 'https://wacca.marv.jp/',
    description: '"The 90\'s Decision" was included.\n(collaboration with Muse Dash)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-10-01',
    date: '2021-10',
    client: 'C4Cat',
    category: 'Mobile Rhythm Game',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description: '"Kalis Mind" was included.\n(adopted at Dynamix submission 2019)',
    media: [{ type: 'YouTube', id: '2pmmDzNI9KY' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2021-09-04',
    date: '2021-09',
    client: 'BTworks',
    category: 'Mobile Rhythm Game',
    title: 'Orzmic',
    url: 'https://twitter.com/orzmic',
    description: 'New written song "Era Breath" was included.',
    media: [{ type: 'YouTube', id: 'XMDy8LZ-Fls' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-09-03',
    date: '2021-09',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: 'New written song "ARIGA THESIS" was included.',
    media: [{ type: 'YouTube', id: 'VMcV2DhVpGg' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-09-02',
    date: '2021-09',
    client: 'Synthnova',
    category: 'Mobile Rhythm Game',
    title: 'RAVON',
    url: 'https://synthnova-ravon.weebly.com/',
    description: '"OCCHOCO-REST-LESS" was included.\n(collaboration with Dynamix)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-09-01',
    date: '2021-09',
    client: '7th Beat Games',
    category: 'PC Rhythm Game',
    title: 'Rhythm Doctor',
    url: 'https://store.steampowered.com/app/774181/Rhythm_Doctor/',
    description: '"The 90\'s Decision" was included.\n(collaboration with Muse Dash)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-08-02',
    date: '2021-08',
    client: 'Para Games',
    category: 'Mobile Rhythm Game',
    title: 'RYCEAM',
    url: 'https://space.bilibili.com/1675655640',
    description: 'Provided the newly written song "The Revival of Eighteen".',
    media: [{ type: 'YouTube', id: 'moukFP9aFVk' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-08-01',
    date: '2021-08',
    client: 'Glacia Studio',
    category: 'Mobile Rhythm Game',
    title: 'AnXiens',
    url: 'https://space.bilibili.com/2107362911',
    description: 'Provided the newly written song "ViViTAL MONO".',
    media: [{ type: 'YouTube', id: 'WaNTcXj_OOM' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-07-01',
    date: '2021-07',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '"OCCHOCO-REST-LESS" was included.',
    media: undefined,
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2021-05-01',
    date: '2021-05',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '"NO ONE YES MAN" was included.\n(collaboration with Phigros)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2021-02-01',
    date: '2021-02',
    client: 'BTworks',
    category: 'Mobile Rhythm Game',
    title: 'Orzmic',
    url: 'https://twitter.com/orzmic',
    description:
      'New written song "BiBiC HEART" & \nexisting song "OTTAMA GAZER" was included.',
    media: [{ type: 'YouTube', id: 'H3V5iEuqrdM' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2021-01-01',
    date: '2021-01',
    client: 'Rayark',
    category: 'Mobile Rhythm Game',
    title: 'Cytus2',
    url: 'https://www.rayark.com/g/cytus2/',
    description:
      '"The 89\'s Momentum", "The 90\'s Decision" was included.\n(collaboration with Muse Dash)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2020-12-01',
    date: '2020-12',
    client: 'ppy pty Ltd.',
    category: 'PC Rhythm Game',
    title: 'osu!',
    url: 'https://osu.ppy.sh/beatmaps/artists/121',
    description: 'Licensing of several songs as Featured Artist.',
    media: undefined,
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2020-11-01',
    date: '2020-11',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: 'New written song "The 90\'s Decision" was included.',
    media: [{ type: 'YouTube', id: 'TNeatjG_nUw' }],
    tags: ['commercial', 'exclusive', 'game'],
  },
  {
    id: '2020-10-01',
    date: '2020-10',
    client: '非可逆リズム',
    category: 'M3-2020 autumn',
    title: undefined,
    url: undefined,
    description: 'Offered "MoriMori Atsushi Remixed by MYUKKE. - 紅 (MYUKKE. REMIX)".',
    media: [{ type: 'YouTube', id: '0sLhAUX_dJ8' }],
    tags: ['other'],
  },
  {
    id: '2020-08-05',
    date: '2020-08',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '"Ginevra" was included.',
    media: [{ type: 'YouTube', id: 'CLkuCQz44Pw' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2020-08-04',
    date: '2020-08',
    client: 'Andamiro',
    category: 'Mobile Rhythm Game',
    title: 'Pump It Up M',
    url: 'https://apps.apple.com/jp/app/pump-it-up-m-beat-finger-step/id1489909857',
    description: '"Yo! Say!! Fairy!!!" was included.',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2020-08-03',
    date: '2020-08',
    client: 'Rayark',
    category: 'Mobile Rhythm Game',
    title: 'Cytus2',
    url: 'https://www.rayark.com/g/cytus2/',
    description: '"Quinsialyn" was included.',
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2020-08-02',
    date: '2020-08',
    client: 'I-Inferno',
    category: 'Mobile / PC Rhythm Game',
    title: 'MUSYNC / MUSYNX',
    url: 'https://store.steampowered.com/app/952040/MUSYNX/',
    description: '"NO ONE YES MAN" was included.\n(collaboration with Phigros)',
    media: undefined,
    tags: ['commercial', 'collaboration', 'game'],
  },
  {
    id: '2020-08-01',
    date: '2020-08',
    client: 'Pigeon Games',
    category: 'Mobile Rhythm Game',
    title: 'Phigros',
    url: 'https://twitter.com/phigros_pgs',
    description: 'New written song "NO ONE YES MAN" was included.',
    media: [{ type: 'YouTube', id: 'ULORJbgWuNQ' }],
    tags: ['non-commercial', 'exclusive', 'game'],
  },
  {
    id: '2020-07-02',
    date: '2020-07',
    client: 'KONAMI',
    category: 'Arcade Rhythm Game',
    title: 'SOUND VOLTEX',
    url: 'https://p.eagate.573.jp/game/sdvx/',
    description: '"DO-IT-AMA-SITE!!!" was included.',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2020-07-01',
    date: '2020-07',
    client: 'MYUKKE_DOT',
    category: 'EP',
    title: 'WE LOVE MYUKKE. EP',
    url: 'https://myukke.bandcamp.com/album/we-love-myukke-ep',
    description: '"WE LOVE MYUKKE. EP" was released.',
    media: [{ type: 'YouTube', id: '0UhntYbh9mY' }],
    tags: ['other'],
  },
  {
    id: '2020-04-01',
    date: '2020-04',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '"FULi AUTO SHOOTER" was included.',
    media: [{ type: 'YouTube', id: 'K0R-FZrSLPo' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2020-03-02',
    date: '2020-03',
    client: 'NEOWIZ',
    category: 'PC Rhythm Game',
    title: 'DJMAX RESPECT V',
    url: 'https://store.steampowered.com/app/960170/DJMAX_RESPECT_V/',
    description: '"WONDER $LOT 777" was included.',
    media: [{ type: 'YouTube', id: 'ARRdg_Ujbio' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2020-03-01',
    date: '2020-03',
    client: 'NEOWIZ',
    category: 'Mobile Rhythm Game',
    title: 'TAPSONIC TOP',
    url: 'https://tapsonic-top.jp/',
    description: '"GAI∀PEX" was included.',
    media: [{ type: 'YouTube', id: 'FiGz1IseiPA' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2020-02-02',
    date: '2020-02',
    client: 'Nanjing AQUATRAX',
    category: 'Contest',
    title: undefined,
    url: undefined,
    description: 'song adopted by INVAXION publicly solicited FirstBattle.',
    media: undefined,
    tags: ['contest', 'game'],
  },
  {
    id: '2020-02-01',
    date: '2020-02',
    client: 'Andamiro',
    category: 'Contest',
    title: undefined,
    url: undefined,
    description:
      '"Yo! Say! Fairy!!!" won a prize at\n3rd PUMP IT UP CONTENTS CREATION CONTEST.',
    media: [{ type: 'YouTube', id: 'clHwJ31kU_Q' }],
    tags: ['contest', 'game'],
  },
  {
    id: '2019-11-01',
    date: '2019-11',
    client: 'Andamiro',
    category: 'Mobile Rhythm Game',
    title: 'Pump It Up M',
    url: 'https://apps.apple.com/jp/app/pump-it-up-m-beat-finger-step/id1489909857',
    description: '"Bestest Frenemy!" was included.',
    media: [{ type: 'YouTube', id: 'Y6jUGwPUSMU' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2019-10-01',
    date: '2019-10',
    client: 'PeroPeroGames',
    category: 'Rhythm Game',
    title: 'Muse Dash',
    url: 'https://store.steampowered.com/app/774171/Muse_Dash/',
    description: '"The 89\'s Momentum" was included.',
    media: [{ type: 'YouTube', id: 'OualnKdbJGo' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2019-09-01',
    date: '2019-09',
    client: 'C4Cat',
    category: 'Mobile Rhythm Game',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description: '"ROAD to 773H" was included.\n(adopted at Dynamix submission 2017)',
    media: [{ type: 'YouTube', id: 'NuoPvHd5OGs' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2019-08-03',
    date: '2019-08',
    client: 'lowiro',
    category: 'Mobile Rhythm Game',
    title: 'Arcaea',
    url: 'https://arcaea.lowiro.com/en',
    description: '"AI[UE]OON" was included.\n(adopted at Arcaea Song Contest 2019)',
    media: [{ type: 'SoundCloud', id: '675480218' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2019-08-02',
    date: '2019-08',
    client: 'Pigeon Games',
    category: 'Mobile Rhythm Game',
    title: 'Phigros',
    url: 'https://twitter.com/phigros_pgs',
    description: '"FULi AUTO SHOOTER" was included.',
    media: [{ type: 'YouTube', id: 'K0R-FZrSLPo' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2019-08-01',
    date: '2019-08',
    client: 'AECRNIA',
    category: 'PC Rhythm Game',
    title: 'URO2',
    url: 'https://keishoabe.wixsite.com/uro2',
    description:
      'Song selected from public song submission\n"Chocomaca Runaway!" was included.',
    media: [{ type: 'SoundCloud', id: '663404258' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2019-07-01',
    date: '2019-07',
    client: 'Bit192 Labs',
    category: 'Contest',
    title: undefined,
    url: 'http://tonesphere.com/sc2019/index_j.html',
    description:
      '"The 89\'s Momentum" received an fine award at\nTone Sphere/Bit192 public song submission.',
    media: [{ type: 'YouTube', id: 'OualnKdbJGo' }],
    tags: ['contest', 'game'],
  },
  {
    id: '2019-03-02',
    date: '2019-03',
    client: 'BOOTH',
    category: 'Goods',
    title: undefined,
    url: 'https://myukke.booth.pm/items/1281208',
    description: '"Nironta" acrylic key holders now available.',
    media: undefined,
    tags: ['other'],
  },
  {
    id: '2019-03-01',
    date: '2019-03',
    client: 'NEOWIZ',
    category: 'PC Rhythm Game',
    title: 'TAPSONIC BOLD',
    url: 'https://store.steampowered.com/app/938220/TAPSONIC_BOLD/',
    description: '"GAI∀PEX" was included.',
    media: [{ type: 'YouTube', id: 'FiGz1IseiPA' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2019-01-01',
    date: '2019-01',
    client: 'Anarch Entertainment',
    category: 'PC Rhythm Game',
    title: 'NOISZ',
    url: 'https://store.steampowered.com/app/681530/NOISZ/',
    description: '"YOKKORASHOW-TIME" was included.',
    media: [{ type: 'SoundCloud', id: '437097003' }],
    tags: ['commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2018-08-01',
    date: '2018-08',
    client: 'Andamiro',
    category: 'Contest',
    title: undefined,
    url: undefined,
    description:
      '"Bestest Frenemy!" advances to the finals at\nThe 2nd PUMP IT UP CONTENTS CREATION CONTEST.',
    media: undefined,
    tags: ['contest', 'game'],
  },
  {
    id: '2018-07-02',
    date: '2018-07',
    client: 'Diverse System',
    category: 'Comic Market94',
    title: 'CELT CHALLENGE 2',
    url: 'https://diverse.jp/dvsp-0203/',
    description: 'Song selected from public song submission\n"Quinsialyn" was included.',
    media: [{ type: 'SoundCloud', id: '482527122' }],
    tags: ['contest', 'cd'],
  },
  {
    id: '2018-07-01',
    date: '2018-07',
    client: 'AECRNIA',
    category: 'PC Rhythm Game',
    title: 'URO',
    url: 'https://keishoabe.wixsite.com/urogames',
    description: 'Song selected from public song submission\n"Fickle Rule" was included.',
    media: [{ type: 'SoundCloud', id: '663403742' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2018-04-01',
    date: '2018-04',
    client: 'NEOWIZ',
    category: 'Contest',
    title: undefined,
    url: undefined,
    description: '"WONDER $LOT 777" & "GAI∀PEX" awarded at YOU X NEOWIZ Music Festival.',
    media: undefined,
    tags: ['contest', 'game'],
  },
  {
    id: '2018-01-01',
    date: '2018-01',
    client: 'Ryuju Orchestra',
    category: 'Mobile Rhythm Game',
    title: 'Tiku taku Concert Sinfonia',
    url: 'http://ryujuorchestra.com/products/tikutakuconcert/',
    description:
      '"TEYAN DAY" was selected by\nChikutaku Concerto\'s 6th public song submissions.',
    media: [{ type: 'SoundCloud', id: '403986864' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2017-10-02',
    date: '2017-10',
    client: '波動Lucario',
    category: 'Mobile Rhythm Game',
    title: 'DanceRail',
    url: 'https://apps.apple.com/jp/app/dancerail3/id1442619889',
    description: '"Elusive Impetus" was included.',
    media: [{ type: 'SoundCloud', id: '322265075' }],
    tags: ['non-commercial', 'non-exclusive', 'game'],
  },
  {
    id: '2017-10-01',
    date: '2017-10',
    client: 'C4Cat',
    category: 'Mobile Rhythm Game',
    title: 'Dynamix',
    url: 'https://twitter.com/c4c4cat',
    description:
      '"OCCHOCO-REST-LESS" was included.\n(adopted at Dynamix submission 2017)',
    media: [{ type: 'YouTube', id: '9A7KhgBY0cQ' }],
    tags: ['commercial', 'contest', 'game'],
  },
  {
    id: '2017-09-01',
    date: '2017-09',
    client: 'Andamiro',
    category: 'Contest',
    title: undefined,
    url: undefined,
    description:
      '"Bestest Fam!" advances to the finals at\nPUMP IT UP CONTENTS CREATION CONTEST.',
    media: undefined,
    tags: ['contest', 'game'],
  },
  {
    id: '2017-05-01',
    date: '2017-05',
    client: 'KONAMI',
    category: 'Arcade Rhythm Game',
    title: 'MUSECA 1+1/2',
    url: 'https://p.eagate.573.jp/game/museca/msc_1half/top/index.html',
    description: '"Shera" was included.\n(adopted at BEMANI NEWFACE CONTEST)',
    media: undefined,
    tags: ['commercial', 'contest', 'game'],
  },
];
