import React from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { HeaderTitle } from '../../atoms/texts/HeaderTitle';
import { Container } from '../../container/Container';
import { Spacer } from '../../container/Spacer';
import { PCMenu } from './PCMenu';
import { SPMenu } from './SPMenu';

export const Header: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <Container padding="4" gap="3">
      <HeaderTitle />
      <Spacer />
      {isMobile && <SPMenu />}
      {!isMobile && <PCMenu />}
    </Container>
  );
};
