import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../container/Container';

export const Contact: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <Container direction="column">
      {/* 日本語 */}
      {i18n.language === 'ja' && (
        <Text fontSize={{ base: 'md', md: 'lg' }}>
          現在、楽曲制作は基本的に<span style={{ fontWeight: 'bold' }}>有償依頼</span>
          のみ受け付けております。 その他の無償依頼については随時相談に対応いたします。
          <br />
          <br />
          ご依頼の際は下記<span style={{ fontWeight: 'bold' }}>メールアドレス</span>
          または、
          <span style={{ fontWeight: 'bold' }}>TwitterのDM</span>等で ご連絡ください。（※
          [at]を@に置き換えてください）
        </Text>
      )}
      {/* 英語 */}
      {i18n.language !== 'ja' && (
        <Text fontSize={{ base: 'md', md: 'lg' }}>
          Currently, I basically only accept
          <span style={{ fontWeight: 'bold' }}> paid requests </span>
          for music production. Other free requests can be discussed at any time.
          <br />
          <br />
          When making a request, please contact me via the
          <span style={{ fontWeight: 'bold' }}> Email </span>address below or
          <span style={{ fontWeight: 'bold' }}> DM on Twitter </span>(*Please replace [at]
          with @)
        </Text>
      )}
      <Text
        fontSize={{ base: 'lg', md: 'xl' }}
        fontWeight="bold"
        textAlign="center"
        py="6"
      >
        youtalkq69310[at]gmail.com
      </Text>
    </Container>
  );
};
