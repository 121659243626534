import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const Language: React.FC = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <Button width="100px" onClick={() => setLang(lang === 'en' ? 'ja' : 'en')}>
      {lang === 'en' ? '日本語' : 'English'}
    </Button>
  );
};
