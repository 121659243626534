import { Link, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ENWorks } from '../../../data/ENWorks';
import { JAWorks } from '../../../data/JAWorks';
import { Work } from '../../../types/Work';
import { TagInfo } from '../../atoms/texts/TagInfo';
import { Box } from '../../container/Box';
import { Container } from '../../container/Container';

export const RecentActivities: React.FC = () => {
  let variant = useBreakpointValue({
    base: 2,
    md: 3,
    lg: 2,
  });
  if (!variant) {
    variant = 2;
  }
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const list = i18n.language === 'ja' ? JAWorks : ENWorks;
  // 直近2,3件分の実績を抽出
  const works: Work[] = [];
  for (let i = 0; i < variant; i += 1) {
    works.push(list[i]);
  }

  return (
    <Container direction="column" gap="6">
      {works.map((work) => (
        <Container direction="column" key={String(work.id)} gap="2">
          {/* 日付 */}
          <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold">
            {work.date}
          </Text>
          {/* タグ */}
          <Container gap="2" wrap="wrap">
            {work.tags.map((tag, index) => (
              <TagInfo key={String(index) + tag} tag={tag} />
            ))}
          </Container>
          <Box>
            <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold">
              {work.client}
            </Text>
            <Container gap="2">
              <Text fontSize={{ base: 'sm', md: 'md' }}>{work.category}</Text>
              {work.title && (
                <>
                  <Text fontSize={{ base: 'sm', md: 'md' }}>-</Text>
                  <Link href={work.url} target="_blank" rel="noreferrer">
                    <Text
                      fontSize={{ base: 'sm', md: 'md' }}
                      fontWeight="bold"
                      borderBottom="1px solid #036"
                    >
                      {work.title}
                    </Text>
                  </Link>
                </>
              )}
            </Container>
            <Text whiteSpace="pre-line">{work.description}</Text>
          </Box>
        </Container>
      ))}
      <Container>
        <Box
          width="auto"
          onClick={() => {
            navigate('/works');
          }}
          fontSize={{ base: 'md', md: 'lg' }}
          fontWeight="bold"
          cursor="pointer"
          _hover={{ textDecoration: 'none', opacity: '0.75' }}
        >
          {t('recentActivities.もっと見る')}
        </Box>
      </Container>
    </Container>
  );
};
