import React from 'react';
import { Text } from '@chakra-ui/react';

type FooterRightsProps = {
  text: string;
  fontSize: string;
};

export const FooterRights: React.FC<FooterRightsProps> = ({ text, fontSize }) => (
  <Text fontSize={fontSize}>{text}</Text>
);
