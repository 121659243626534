export type TagObj = {
  key: Tag;
  colorScheme: string;
  nameJA: string;
  nameEN: string;
};

export type Tag =
  | 'commercial'
  | 'non-commercial'
  | 'exclusive'
  | 'non-exclusive'
  | 'contest'
  | 'collaboration'
  | 'game'
  | 'cd'
  | 'other';

export const TagDetails: TagObj[] = [
  { key: 'commercial', nameJA: '#商用', nameEN: '#commercial', colorScheme: 'blue' },
  {
    key: 'non-commercial',
    nameJA: '#非商用',
    nameEN: '#non-commercial',
    colorScheme: 'red',
  },
  {
    key: 'exclusive',
    nameJA: '#書き下ろし楽曲',
    nameEN: '#exclusive',
    colorScheme: 'orange',
  },
  {
    key: 'non-exclusive',
    nameJA: '#既存楽曲',
    nameEN: '#non-exclusive',
    colorScheme: 'whatsapp',
  },
  { key: 'contest', nameJA: '#公募', nameEN: '#contest', colorScheme: 'facebook' },
  {
    key: 'collaboration',
    nameJA: '#イベント交換楽曲',
    nameEN: '#collaboration',
    colorScheme: 'green',
  },
  { key: 'game', nameJA: '#ゲーム', nameEN: '#game', colorScheme: 'purple' },
  { key: 'cd', nameJA: '#CD', nameEN: '#CD', colorScheme: 'pink' },
  { key: 'other', nameJA: '#その他', nameEN: '#other', colorScheme: 'teal' },
];
