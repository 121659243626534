import React from 'react';
import { Language } from '../../atoms/buttons/Language';
import { HeaderMenu } from '../../atoms/texts/HeaderMenu';
import { Container } from '../../container/Container';

export const PCMenu: React.FC = () => (
  <Container align="center" gap="3">
    <HeaderMenu href="/" width="130px" fontSize="xl" text="HOME" />
    <HeaderMenu href="/works" width="130px" fontSize="xl" text="WORKS" />
    <Language />
  </Container>
);
