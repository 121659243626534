import React from 'react';
import { SectionTitle } from '../../atoms/texts/SectionTitle';
import { Container } from '../../container/Container';

type WorkCardProps = {
  children: React.ReactNode;
  title: string;
};

export const WorkCard: React.FC<WorkCardProps> = ({ children, title }) => (
  <Container direction="column" gap="4" bg="white" w="100%" borderRadius="20" p={4}>
    <SectionTitle fontSize={{ base: 'xl', md: '3xl' }} text={title} />
    {children}
  </Container>
);
