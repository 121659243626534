import React from 'react';

export const SoundCloudList: React.FC = () => (
  // eslint-disable-next-line jsx-a11y/iframe-has-title
  <iframe
    height="500"
    scrolling="no"
    frameBorder="no"
    allow="autoplay"
    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/150956093&color=%232eb4ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
  />
);
