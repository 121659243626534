import { Tag as ChakraTag } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, TagDetails } from '../../../types/Tag';

type TagInfoProps = {
  tag: Tag;
};

export const TagInfo: React.FC<TagInfoProps> = ({ tag }) => {
  const { i18n } = useTranslation();
  let obj = TagDetails.find((e) => e.key === tag);
  if (!obj) {
    obj = { key: 'other', nameJA: '#その他', nameEN: '#other', colorScheme: 'teal' };
  }
  let name = obj?.nameJA;
  if (i18n.language === 'en') {
    name = obj?.nameEN;
  }

  return (
    <ChakraTag
      cursor="default"
      borderRadius="full"
      variant="solid"
      colorScheme={obj.colorScheme}
    >
      {name}
    </ChakraTag>
  );
};
