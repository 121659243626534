import React from 'react';
import { FaBandcamp } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';

export const LinkBandcamp: React.FC = () => (
  <Button
    as="a"
    href="https://myukke.bandcamp.com"
    target="_blank"
    colorScheme="teal"
    leftIcon={<FaBandcamp />}
  >
    Bandcamp
  </Button>
);
