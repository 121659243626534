import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../organisms/Card';
import { Profile } from '../organisms/home/Profile';
import { Icon } from '../organisms/home/Icon';
import { LatestRelease } from '../organisms/home/LatestRelease';
import { Twitter } from '../atoms/embed/Twitter';
import { SoundCloudList } from '../atoms/embed/SoundCloudList';
import { Links } from '../organisms/home/Links';
import { Contact } from '../organisms/home/Contact';
import { RecentActivities } from '../organisms/home/RecentActivities';
import { Container } from '../container/Container';
import { useIsMobile } from '../../hooks/useIsMobile';

export const HomeTemplate: React.FC = () => {
  const isMobile = useIsMobile();
  const [t] = useTranslation();

  return (
    <Container flexWrap="wrap" gap="8" justifyContent="space-between">
      <Card title={t('home.最新リリース')}>
        <LatestRelease />
      </Card>
      {isMobile && (
        <>
          <Card title={t('home.最近の活動')}>
            <RecentActivities />
          </Card>
          <Card title={t('home.プロフィール')}>
            <Profile />
          </Card>
          <Card title={t('home.アイコン')}>
            <Icon />
          </Card>
        </>
      )}
      {!isMobile && (
        <>
          <Card title={t('home.アイコン')}>
            <Icon />
          </Card>
          <Card title={t('home.最近の活動')}>
            <RecentActivities />
          </Card>
          <Card title={t('home.プロフィール')}>
            <Profile />
          </Card>
        </>
      )}
      <Card title={t('home.リンク集')}>
        <Links />
      </Card>
      <Card title={t('home.ご連絡について')}>
        <Contact />
      </Card>
      <Card title={t('home.Twitter')}>
        <Twitter />
      </Card>
      <Card title={t('home.SoundCloud')}>
        <SoundCloudList />
      </Card>
    </Container>
  );
};
