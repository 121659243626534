import React from 'react';
import { FaTwitter } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';

export const LinkTwitter: React.FC = () => (
  <Button
    as="a"
    href="https://twitter.com/youtalkq69310"
    target="_blank"
    colorScheme="twitter"
    leftIcon={<FaTwitter />}
  >
    Twitter
  </Button>
);
