import React from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

type HeaderButtonProps = {
  children: React.ReactNode;
  btnRef: React.RefObject<HTMLButtonElement>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const HeaderButton: React.FC<HeaderButtonProps> = ({
  children,
  btnRef,
  isOpen,
  onOpen,
  onClose,
}) => (
  <>
    <Button ref={btnRef} onClick={onOpen} variant="menu">
      <HamburgerIcon boxSize="6" />
    </Button>
    <Drawer isOpen={isOpen} onClose={onClose} placement="top" finalFocusRef={btnRef}>
      <DrawerOverlay>
        <DrawerContent background="#ff5757">
          <DrawerCloseButton color="white" fontSize="16px" />
          <DrawerHeader fontSize="3xl" color="white">
            Menu
          </DrawerHeader>
          <DrawerBody pr={6} pb={6} pl={6}>
            {children}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  </>
);
