import React from 'react';
import { FaSoundcloud } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';

export const LinkSoundCloud: React.FC = () => (
  <Button
    as="a"
    href="https://soundcloud.com/myukke"
    target="_blank"
    colorScheme="orange"
    leftIcon={<FaSoundcloud />}
  >
    SoundCloud
  </Button>
);
