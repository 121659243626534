import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  CheckboxGroup,
  Text,
} from '@chakra-ui/react';
import Masonry from 'react-masonry-css';
import { useTranslation } from 'react-i18next';
import { JAWorks } from '../../data/JAWorks';
import { WorkInfo } from '../organisms/work/WorkInfo';
import './WorksTemplate.css';
import '../organisms/work/Pagination.css';
import { WorkCard } from '../organisms/work/WorkCard';
import { Container } from '../container/Container';
import { Box } from '../container/Box';
import { Pagination } from '../organisms/work/Pagination';
import { TagDetails } from '../../types/Tag';
import { Work } from '../../types/Work';
import { ENWorks } from '../../data/ENWorks';

export const WorksTemplate: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [defaultValue, setDefaultValue] = useState<(string | number)[]>([]);
  // 中身弄らない全体リスト
  const [baseList, setBaseList] = useState<Work[]>(JAWorks);
  // 中身フィルタリングする全体リスト
  const [workList, setWorkList] = useState<Work[]>(baseList);

  const [offset, setOffset] = useState(0); // 何番目のアイテムから表示するか
  const [pageNumber, setpageNumber] = useState(0);
  const perPage = 10; // 1ページあたりに表示したいアイテムの数
  // クリック時のfunction
  const handlePageChange = (data: { selected: number }) => {
    setpageNumber(data.selected); // クリックした部分のページ数が{selected: 2}のような形で返ってくる
    setOffset(data.selected * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
    // window.history.pushState({}, '', `?page=${data.selected + 1}`);
  };

  function getIsDuplicate(arr1: Array<string | number>, arr2: Array<string | number>) {
    return (
      [...arr1, ...arr2].filter((item) => arr1.includes(item) && arr2.includes(item))
        .length > 0
    );
  }

  const handler = (value: (string | number)[]) => {
    setDefaultValue(value);
    if (value.length !== 0) {
      setWorkList(baseList.filter((el) => getIsDuplicate(value, el.tags)));
    } else {
      setWorkList(baseList);
    }
  };

  useEffect(() => {
    setpageNumber(0);
    setOffset(0);
  }, [workList]);

  useEffect(() => {
    const list = i18n.language === 'ja' ? JAWorks : ENWorks;
    setBaseList(list);
    setWorkList(baseList);
    setDefaultValue([]);
  }, [i18n.language, baseList]);

  return (
    <>
      <Container
        direction="column"
        mb="20px"
        bg="white"
        borderRadius="20"
        paddingX={4}
        w="100%"
        maxW="100%"
        justify="center"
        gap="3"
        overflow="hidden"
      >
        <Accordion allowToggle>
          <AccordionItem border="none">
            <AccordionButton bg="white" _hover={{ bg: 'white' }}>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold">{t('works.タグで絞り込み検索')}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Container gap="3" wrap="wrap">
                <CheckboxGroup onChange={handler} value={defaultValue}>
                  {TagDetails.map((tagDetail) => (
                    <Checkbox
                      key={tagDetail.key}
                      value={tagDetail.key}
                      size="lg"
                      colorScheme={tagDetail.colorScheme}
                      variant="frame"
                    >
                      {i18n.language === 'ja' ? (
                        <Text color="white">{tagDetail.nameJA}</Text>
                      ) : (
                        <Text color="white">{tagDetail.nameEN}</Text>
                      )}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </Container>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
      <Box paddingBottom="20px" borderRadius="16px">
        <Pagination
          pageNumber={pageNumber}
          dataCounts={workList.length}
          perPage={perPage}
          onChange={handlePageChange}
        />
      </Box>
      <Masonry
        breakpointCols={{ default: 2, 768: 1 }}
        className="masonry-grid"
        columnClassName="masonry-grid-column"
      >
        {workList.slice(offset, offset + perPage).map((work) => (
          <Box key={String(work.id)} style={{ marginBottom: '30px' }}>
            <WorkCard title={work.date}>
              <WorkInfo work={work} />
            </WorkCard>
          </Box>
        ))}
      </Masonry>
      <Pagination
        pageNumber={pageNumber}
        dataCounts={workList.length}
        perPage={perPage}
        onChange={handlePageChange}
      />
    </>
  );
};
