import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '../container/Container';
import { Footer } from '../organisms/footer/Footer';
import { Header } from '../organisms/header/Header';

export const MainTemplate: React.FC = () => (
  <>
    {/* 大枠 */}
    <Container
      align="center"
      direction="column"
      color="#036"
      bg="#ff5757"
      w="100%"
      minH="100vh"
    >
      {/* コンテンツ枠 */}
      <Container direction="column" width="100%" maxW="1440px">
        <Header />
        <Container direction="column" padding="4">
          <Outlet />
        </Container>
        <Footer />
      </Container>
    </Container>
  </>
);
