import { ChakraProvider, extendTheme, StyleFunctionProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './components/pages/Home';
import { Works } from './components/pages/Works';
import { MainTemplate } from './components/templates/MainTemplate';
import './i18next/config';

const App = () => {
  let userLanguage = window.navigator.language;
  if (userLanguage !== 'ja') {
    userLanguage = 'en';
  }
  const { i18n } = useTranslation();
  const [lang] = useState(userLanguage);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <ChakraProvider
      theme={extendTheme({
        components: {
          Button: {
            variants: {
              menu: {
                width: '50px',
                height: '50px',
                color: '#ff5757',
                bg: 'white',
                transitionProperty: 'none',
                _hover: {
                  border: '2px solid #fff',
                  color: 'white',
                  bg: 'none',
                },
              },
            },
          },
          Link: {
            baseStyle: {
              _hover: { textDecoration: 'none', opacity: '0.75' },
            },
          },
          Checkbox: {
            baseStyle: {
              control: {
                bg: `white`,
                border: `1px solid`,
              },
              container: {
                fontSize: `md`,
                _hover: {
                  borderColor: `textLink`,
                },
              },
            },
            variants: {
              frame: (props: StyleFunctionProps) => ({
                container: {
                  borderRadius: `base`,
                  bg: `${props.colorScheme}.500`,
                  py: `1`,
                  px: `2`,
                  _checked: {
                    _disabled: {
                      bg: `none`,
                    },
                  },
                },
                label: {
                  fontSize: `sm`,
                },
              }),
            },
          },
        },
      })}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainTemplate />}>
            <Route index element={<Home />} />
            <Route path="works" element={<Works />} />
            {/* 以下はリニューアル前にあったページのリダイレクト用 */}
            <Route path="about.html" element={<Navigate to="/" replace />} />
            <Route path="contact.html" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
