import React from 'react';
import { Flex } from '@chakra-ui/react';
import { BaseContainerProp } from './types';
import { ResponsiveStyles } from '../types';

type FlexContainerProps = BaseContainerProp &
  Partial<
    ResponsiveStyles<{
      overflow: string;
      direction: `row` | `column`;
      wrap: `wrap` | `wrap-reverse`;
      align: `center` | `flex-start` | `flex-end` | `baseline`;
      justify: `center` | `flex-start` | `flex-end` | `space-between` | `space-around`;
    }>
  >;

export const Container: React.FC<FlexContainerProps> = ({
  children,
  direction,
  wrap,
  align,
  justify,
  ...rest
}) => (
  <Flex direction={direction} wrap={wrap} align={align} justify={justify} {...rest}>
    {children}
  </Flex>
);
