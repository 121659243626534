import { Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Work } from '../../../types/Work';
import { SoundCloud } from '../../atoms/embed/SoundCloud';
import { YouTube } from '../../atoms/embed/YouTube';
import { TagInfo } from '../../atoms/texts/TagInfo';
import { Container } from '../../container/Container';

type WorkProps = {
  work: Work;
};

export const WorkInfo: React.FC<WorkProps> = ({ work }) => (
  <Container direction="column" gap="2">
    <Container wrap="wrap" gap="1">
      {work.tags.map((tag, index) => (
        <TagInfo key={String(index) + tag} tag={tag} />
      ))}
    </Container>
    <Text fontSize="2xl" fontWeight="bold">
      {work.client}
    </Text>
    <hr style={{ borderTop: '2px solid #036' }} />
    <Container wrap="wrap" gap="3" rowGap="0">
      <Text>{work.category}</Text>
      {work.title && (
        <>
          <Text>-</Text>
          <Link href={work.url} target="_blank" rel="noreferrer">
            <Text fontWeight="bold" borderBottom="1px solid #036">
              {work.title}
            </Text>
          </Link>
        </>
      )}
    </Container>
    <Text whiteSpace="pre-line">{work.description}</Text>
    {work.media &&
      work.media.map((val) => {
        switch (val.type) {
          case 'SoundCloud':
            return <SoundCloud key={val.id} id={val.id} />;
          case 'YouTube':
            return <YouTube key={val.id} id={val.id} />;
          default:
            return null;
        }
      })}
  </Container>
);
