import React from 'react';
import { SiBilibili } from 'react-icons/si';
import { Button } from '@chakra-ui/react';

export const LinkBilibili: React.FC = () => (
  <Button
    as="a"
    href="https://space.bilibili.com/1498658243/"
    target="_blank"
    colorScheme="messenger"
    leftIcon={<SiBilibili />}
  >
    bilibili
  </Button>
);
