import React from 'react';
import { FaYoutube } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';

export const LinkYouTube: React.FC = () => (
  <Button
    as="a"
    href="https://www.youtube.com/c/MYUKKE"
    target="_blank"
    colorScheme="red"
    leftIcon={<FaYoutube />}
  >
    YouTube
  </Button>
);
