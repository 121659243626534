/* eslint-disable jsx-a11y/iframe-has-title */
import { AspectRatio, Image } from '@chakra-ui/react';
import React, { useState } from 'react';

type YouTubeProps = {
  id: string;
};

export const YouTube: React.FC<YouTubeProps> = ({ id }) => {
  const [isThumbnail, setIsThumbnail] = useState(true);

  return (
    <AspectRatio maxW="100%" ratio={16 / 9}>
      {isThumbnail ? (
        <Image
          src={`https://img.youtube.com/vi/${id}/maxresdefault.jpg`}
          onClick={() => setIsThumbnail(false)}
          alt="thumbnail"
          cursor="pointer"
          _hover={{ opacity: '0.75' }}
        />
      ) : (
        <iframe
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </AspectRatio>
  );
};
