import React from 'react';
import { Text, ResponsiveValue } from '@chakra-ui/react';

type SectionTitleProps = {
  text: string;
  fontSize: ResponsiveValue<string | number>;
};

export const SectionTitle: React.FC<SectionTitleProps> = ({ text, fontSize }) => (
  <Text fontSize={fontSize} fontWeight="bold">
    {text}
  </Text>
);
