import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { HeaderButton } from '../../atoms/buttons/HeaderButton';
import { Language } from '../../atoms/buttons/Language';
import { HeaderMenu } from '../../atoms/texts/HeaderMenu';
import { Container } from '../../container/Container';
import { Spacer } from '../../container/Spacer';

export const SPMenu: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const width = '100%';

  return (
    <HeaderButton btnRef={btnRef} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <Container direction="column" gap="2">
        <HeaderMenu
          href="/"
          padding="2"
          width={width}
          fontSize="2xl"
          text="HOME"
          onClose={onClose}
        />
        <Spacer />
        <HeaderMenu
          href="/works"
          padding="2"
          width={width}
          fontSize="2xl"
          text="WORKS"
          onClose={onClose}
        />
        <Container w={width} paddingTop="4" justify="center">
          <Language />
        </Container>
      </Container>
    </HeaderButton>
  );
};
