import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '../../container/Box';

type HeaderMenuProps = {
  text: string;
  fontSize: string;
  width?: string;
  href: string;
  padding?: string;
  onClose?: () => void;
};

export const HeaderMenu: React.FC<HeaderMenuProps> = ({
  text,
  fontSize,
  width = '36',
  href,
  padding = 1,
  onClose,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        if (onClose) {
          onClose();
        }
        navigate(href);
      }}
      cursor="pointer"
      _hover={{ bg: 'white', color: '#ff5757' }}
      width={width}
      fontSize={fontSize}
      color="white"
      borderRadius="20px"
      textAlign="center"
      border="2px"
      fontWeight="bold"
      padding={padding}
    >
      {text}
    </Box>
  );
};
