import { Box as ChakraBox, BoxProps as ChakraBoxProps } from '@chakra-ui/react';
import React from 'react';

import { ChildrenProps } from '../types';

type BoxProps = ChildrenProps & ChakraBoxProps;

export const Box: React.FC<BoxProps> = ({ children, ...rest }) => (
  <ChakraBox {...rest}>{children}</ChakraBox>
);
