import React from 'react';
import { SiPixiv } from 'react-icons/si';
import { Button } from '@chakra-ui/react';

export const LinkBooth: React.FC = () => (
  <Button
    as="a"
    href="https://myukke.booth.pm/items/1281208"
    target="_blank"
    colorScheme="purple"
    leftIcon={<SiPixiv />}
  >
    Booth
  </Button>
);
