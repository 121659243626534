import React from 'react';
import { Flex } from '@chakra-ui/react';
import { LinkTwitter } from '../../atoms/buttons/LinkTwitter';
import { LinkSoundCloud } from '../../atoms/buttons/LinkSoundCloud';
import { LinkYouTube } from '../../atoms/buttons/LinkYouTube';
import { LinkBandcamp } from '../../atoms/buttons/LinkBandcamp';
import { LinkSpotify } from '../../atoms/buttons/LinkSpotify';
import { LinkAppleMusic } from '../../atoms/buttons/LinkAppleMusic';
import { LinkNeteaseCloudMusic } from '../../atoms/buttons/LinkNeteaseCloudMusic';
import { LinkBilibili } from '../../atoms/buttons/LinkBilibili';
import { LinkBooth } from '../../atoms/buttons/LinkBooth';
import { LinkYouTubeMusic } from '../../atoms/buttons/LinkYouTubeMusic';
import { LinkAmazonMusic } from '../../atoms/buttons/LinkAmazonMusic';

export const Links: React.FC = () => (
  <Flex wrap="wrap" gap="3" rowGap="3">
    <LinkTwitter />
    <LinkSoundCloud />
    <LinkYouTube />
    <LinkYouTubeMusic />
    <LinkAmazonMusic />
    <LinkSpotify />
    <LinkBandcamp />
    <LinkAppleMusic />
    <LinkNeteaseCloudMusic />
    <LinkBilibili />
    <LinkBooth />
  </Flex>
);
