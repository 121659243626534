import React from 'react';
import { FaApple } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';

export const LinkAppleMusic: React.FC = () => (
  <Button
    as="a"
    href="https://music.apple.com/jp/artist/myukke/1438271473"
    target="_blank"
    colorScheme="pink"
    leftIcon={<FaApple />}
  >
    AppleMusic
  </Button>
);
