/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

type SoundCloudProps = {
  id: string;
};

export const SoundCloud: React.FC<SoundCloudProps> = ({ id }) => (
  <iframe
    width="100%"
    height="166"
    scrolling="no"
    frameBorder="no"
    allow="autoplay"
    src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&color=%232eb4ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
  />
  // <iframe
  //   // width="100%"
  //   // height="600"
  //   scrolling="no"
  //   frameBorder="no"
  //   allow="autoplay"
  //   src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&color=%232eb4ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`}
  // />
);
