import React from 'react';
import { Image as ChakraImage } from '@chakra-ui/react';

type ImageProps = {
  src: string;
  alt?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  hover?: object;
};

export const Image: React.FC<ImageProps> = ({
  src,
  alt,
  borderRadius = '0',
  width,
  height,
  hover,
}) => (
  <ChakraImage
    src={src}
    alt={alt}
    borderRadius={borderRadius}
    htmlWidth={width}
    htmlHeight={height}
    _hover={hover}
  />
);
