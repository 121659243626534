import React from 'react';
import { AiFillAmazonCircle } from 'react-icons/ai';
import { Button } from '@chakra-ui/react';

export const LinkAmazonMusic: React.FC = () => (
  <Button
    as="a"
    href="https://www.amazon.co.jp/music/player/artists/B07JVVWVZ5/myukke"
    target="_blank"
    color="black"
    colorScheme="yellow"
    leftIcon={<AiFillAmazonCircle />}
  >
    AmazonMusic
  </Button>
);
