import React from 'react';
import { AspectRatio, Image } from '@chakra-ui/react';
import { Container } from '../../container/Container';

type IconProps = {
  width?: string;
};

export const Icon: React.FC<IconProps> = ({ width }) => (
  <Container width="100%">
    <Container width="100%" gap="4" align="center" justify="center">
      <AspectRatio w={{ base: '50%', md: '50%' }} ratio={1}>
        <Image
          src={`${process.env.PUBLIC_URL}/Astro_Nironta.jpg`}
          alt="MYUKKE. OFFICIAL SITE"
          borderRadius="20px"
          w={width}
          objectFit="cover"
        />
      </AspectRatio>
      <AspectRatio w={{ base: '40%', md: '40%' }} ratio={3 / 4}>
        <Image
          src={`${process.env.PUBLIC_URL}/Nironta.png`}
          alt="MYUKKE. OFFICIAL SITE"
          borderRadius="20px"
          w={width}
          objectFit="cover"
        />
      </AspectRatio>
    </Container>
  </Container>
);
