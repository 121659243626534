import React from 'react';
import { RiNeteaseCloudMusicFill } from 'react-icons/ri';
import { Button } from '@chakra-ui/react';

export const LinkNeteaseCloudMusic: React.FC = () => (
  <Button
    as="a"
    href="https://music.163.com/#/artist?id=30206492"
    target="_blank"
    colorScheme="red"
    leftIcon={<RiNeteaseCloudMusicFill />}
  >
    NeteaseCloudMusic
  </Button>
);
