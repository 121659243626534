import React from 'react';
import { FaSpotify } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';

export const LinkSpotify: React.FC = () => (
  <Button
    as="a"
    href="https://open.spotify.com/artist/6lB8mMP59che6XMMWCtwMq?si=CW8ysTSHRD2Sumebc0W6jA&dl_branch=1"
    target="_blank"
    colorScheme="whatsapp"
    leftIcon={<FaSpotify />}
  >
    Spotify
  </Button>
);
